import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadImgFile,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { InventoryRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import AddRawMaterialUnit from "./AddRawMaterialUnit";
import { useSelector } from "react-redux";

const AddRawMaterial = ({
  editMaterial,
  setEditMaterial,
  handleGetAllMaterial,
  handleGetAllVendor,
  vendorLoader,
  allVendors,
}) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [materialCode, setMaterialCode] = useState("");
  const [openingStock, setOpeningStock] = useState("");
  const [closingStock, setClosingStock] = useState("");
  const [selectCategory, setSelectCategory] = useState("Select Category");
  const [brandName, setBrandName] = useState("");
  const [unit, setUnit] = useState("");
  const [unitId, setUnitId] = useState("");
  const [addUnitModal, setAddUnitModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [allUnits, setAllUnits] = useState([]);
  const [price, setPrice] = useState("");
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [lowStock, setlowStock] = useState("");
  const [billNumber, setbillNumber] = useState("");
  const [image1, setimage1] = useState(null);
  const [image2, setimage2] = useState(null);
  const [image3, setimage3] = useState(null);

  const filteredUnits = allUnits?.filter((unt) =>
    unt?.name?.toLowerCase()?.includes(filter.toLowerCase())
  );

  //Select Vendor
  const [selectedVendor, setselectedVendor] = useState("");
  const [vendorData, setvendorData] = useState("");
  const [vendorfFlter, setVendorFilter] = useState("");
  const filteredVendors = filterSearchData(allVendors, vendorfFlter);

  useEffect(() => {
    setPricePerUnit(openingStock !== 0 ? price / openingStock : 0 || 0);
  }, [openingStock, price]);

  useEffect(() => {
    if (editMaterial) {
      setMaterialName(editMaterial?.name);
      setMaterialCode(editMaterial?.inventoryItemCode);
      setOpeningStock(editMaterial?.openingStock);
      setClosingStock(editMaterial?.closingStock);
      setSelectCategory(editMaterial?.category);
      setUnit(editMaterial?.unit);
      setlowStock(editMaterial?.lowInventoryCount);
      setBrandName(editMaterial?.brand);
    }
  }, [editMaterial]);

  const allCategories = [
    {
      name: "Vegetables",
    },
    {
      name: "Non-veg",
    },
    {
      name: "Ingredients",
    },
    {
      name: "Others",
    },
  ];

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setMaterialName("");
    setMaterialCode("");
    setOpeningStock("");
    setClosingStock("");
    setUnit("");
    setlowStock("");
    setSelectCategory("Select Category");
    setBrandName("");
    setEditMaterial("");
    setPrice("");
    setvendorData("");
    setselectedVendor("");
    setbillNumber("");
    setimage1(null);
    setimage2(null);
    setimage3(null);
    const closeModalDom = document.getElementById("add_raw_material");
    if (closeModalDom) closeModalDom.click();
  };

  // ADD RAWMATERIAL FUNCTION API CALL
  const handleAddRawMaterial = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    const uploadImg1 = await handleUploadImgFile(image1);
    const uploadImg2 = await handleUploadImgFile(image2);
    const uploadImg3 = await handleUploadImgFile(image3);

    const stockImages = [
      uploadImg1,
      uploadImg2,
      uploadImg3,
    ].filter((img) => img !== null);
    if (stockImages.length) {
      try {
        for (const image of stockImages) {
          temp.push(image);
        }
      } catch (error) {
        toast.error("Image uploading failed, try again later.");
        return;
      }
    }
      const data = {
        businessId: getCurrentWorkspaceId(),
        inventoryItemCode: materialCode,
        openingStock,
        price,
        perUnitPrice: pricePerUnit,
        stockType: "new",
        name: materialName,
        unit: unitId,
        category: selectCategory,
        brand: brandName,
        vendorId: selectedVendor,
        createdBy: user?._id,
        lowInventoryCount: lowStock,
        billNumber,
        images: temp,
      };
      const res = await ApiCall(
        "post",
        InventoryRelatedEndPoints.addInventory,
        data
      );
      if (res?.success) {
        handleReset();
        handleGetAllMaterial();
        toast.success("Add Raw material successfully!");
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
  };

  // EDIT RAWMATERIAL FUNCTION API CALL
  const handleEditRawMaterial = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      inventoryId: editMaterial?._id,
      openingStock,
      closingStock,
      name: materialName,
      brand: brandName,
      actionBy: user?._id,
      lowInventoryCount: lowStock,
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.editInventory,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllMaterial();
      toast.success("Edit Raw material successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleGetAllUnit = async () => {
    const data = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getInventoryUnit,
      data
    );
    if (res?.success) {
      setAllUnits(res?.unit);
    }
  };

  useEffect(() => {
    handleGetAllUnit();
  }, []);

  return (
    <div
      id="add_raw_material"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered " role="document">
        <div className="modal-content mh-700p">
          <div className="modal-body ">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editMaterial === "" ? "Add" : "Edit"} Raw Material
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editMaterial === "") {
                  handleAddRawMaterial(e);
                } else {
                  handleEditRawMaterial(e);
                }
              }}
            >
              <div className="row gx-3 px-3 w-100 mh-500p overflow-auto  ">
                {!editMaterial && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Vendor {reuiredField}
                        <span
                          className="pointer ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#add_vendor"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="primary"
                            class="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                          </svg>
                        </span>
                      </label>
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p `}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => {
                              handleGetAllVendor();
                              setVendorFilter("");
                            }}
                          >
                            {selectedVendor ? (
                              <div className="d-flex justify-content-between">
                                {vendorData}
                                {selectedVendor !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-1"
                                    onClick={() => {
                                      setselectedVendor("");
                                      setVendorFilter("");
                                      setvendorData("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select Vendor"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu ">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                placeholder="Search.."
                                className="form-control"
                                value={vendorfFlter}
                                onChange={(e) =>
                                  setVendorFilter(e.target.value)
                                }
                              />
                            </form>
                            {vendorLoader ? (
                              <span className="d-flex text-center mt-1">
                                Loading...
                              </span>
                            ) : (
                              <div
                                style={{
                                  maxHeight: "30vh",
                                  overflow: "auto",
                                }}
                              >
                                {filteredVendors?.map((vendor, index) => (
                                  <div
                                    className="dropdown-item text-capitalize"
                                    key={vendor._id}
                                    onClick={() => {
                                      setselectedVendor(vendor._id);
                                      setvendorData(vendor?.name);
                                    }}
                                  >
                                    {index + 1}. {vendor?.name}
                                  </div>
                                ))}
                              </div>
                            )}
                            {!vendorLoader && filteredVendors?.length === 0 && (
                              <span className="d-flex text-center mt-1">
                                No Vendors found
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Name {reuiredField}</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Enter Name"
                      value={materialName}
                      onChange={(e) => setMaterialName(e.target.value)}
                    />
                  </div>
                </div>
                {!editMaterial && (
                  <div className="col-sm-6">
                    <label className="text-dark fw-300 fs-6 mb-2">
                      Choose Unit {reuiredField}
                    </label>
                    <div className="form-group">
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium  text-dark text-capitalize border rounded px-4 py-2  w-100  ${
                              editMaterial ? "bg-light text-disabled" : ""
                            }`}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            disabled={editMaterial}
                            onClick={() => setFilter("")}
                          >
                            {unit ? (
                              <>
                                {unit}
                                {unit !== "" && !editMaterial && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setUnit("");
                                      setFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </>
                            ) : (
                              "Select unit"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search.."
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </form>
                            <div
                              style={{ maxHeight: "25vh", overflow: "auto" }}
                            >
                              {filteredUnits?.map((unt) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={unt.name}
                                  onClick={() => {
                                    setUnit(unt.name);
                                    setUnitId(unt?._id);
                                  }}
                                >
                                  {unt.name}
                                </div>
                              ))}
                            </div>
                            {filteredUnits?.length === 0 && (
                              <span className="d-flex text-center mt-1">
                                No Unit found
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!editMaterial && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Category {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            required
                            disabled={editMaterial}
                            value={selectCategory}
                            onChange={(e) => {
                              setSelectCategory(e.target.value);
                            }}
                          >
                            <option>Select Category</option>
                            {allCategories?.map((cat) => {
                              return (
                                <option value={cat?.name}>{cat?.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!editMaterial && (
                  <div className="col-sm-6">
                    <label className="text-dark fw-300 fs-6">
                      Code {reuiredField}
                    </label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      required
                      placeholder="Enter Code"
                      disabled={editMaterial}
                      value={materialCode}
                      onChange={(e) => setMaterialCode(e.target.value)}
                    />
                  </div>
                )}
                {!editMaterial && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Vendor Id/Bill/Challan {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Bill Number"
                        required
                        value={billNumber}
                        onChange={(e) => setbillNumber(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {!editMaterial && (
                  <div className="row gx-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">
                          Upload Bill Attachment
                        </label>

                        <div className="d-flex dropify-square gap-3 mt-3">
                          {image1 ? (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="file1"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={image1}
                                onChange={(event) => {
                                  setimage1(event);
                                }}
                              />
                              <label
                                htmlFor="file1"
                                className="position-relative pointer"
                              >
                                <img
                                  htmlFor="file1"
                                  id="local_show_img_1"
                                  src={handlePreviewFileInLocal(image1)}
                                  className="dropify-wrapper text-center"
                                  alt="img1"
                                />
                                <span
                                  className="position-top-end-overflow"
                                  onClick={() => {
                                    setimage1(null);
                                    setimage2(null);
                                    setimage3(null);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </span>
                              </label>
                            </>
                          ) : (
                            <span className="dropify-wrapper text-center">
                              <input
                                type="file"
                                name="image"
                                id="file1"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                onChange={(event) => {
                                  setimage1(event);
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-upload"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                              </svg>
                              <label
                                htmlFor="file1"
                                className="pointer text-center"
                              >
                                Upload Image
                              </label>
                            </span>
                          )}
                          {image2 ? (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="file2"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={image2}
                                onChange={(event) => {
                                  setimage2(event);
                                }}
                              />
                              <label
                                htmlFor="file2"
                                className="position-relative pointer"
                              >
                                <img
                                  htmlFor="file2"
                                  id="local_show_img_2"
                                  src={handlePreviewFileInLocal(image2)}
                                  className="dropify-wrapper text-center"
                                  alt="img2"
                                />
                                <span
                                  className="position-top-end-overflow"
                                  onClick={() => {
                                    setimage2(null);
                                    setimage3(null);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </span>
                              </label>
                            </>
                          ) : (
                            <span
                              className={`dropify-wrapper text-center ${
                                !image1 ? "bg-light text-disabled" : ""
                              }`}
                            >
                              <input
                                type="file"
                                name="image"
                                id="file2"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={!image1}
                                onChange={(event) => {
                                  setimage2(event);
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-upload"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                              </svg>
                              <label
                                htmlFor="file2"
                                className="pointer text-center"
                              >
                                Upload Image
                              </label>
                            </span>
                          )}
                          {image3 ? (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="file3"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={image3}
                                onChange={(event) => {
                                  setimage3(event);
                                }}
                              />
                              <label
                                htmlFor="file3"
                                className="position-relative pointer"
                              >
                                <img
                                  htmlFor="file3"
                                  id="local_show_img_3"
                                  src={handlePreviewFileInLocal(image3)}
                                  className="dropify-wrapper text-center"
                                  alt="img3"
                                />
                                <span
                                  className="position-top-end-overflow"
                                  onClick={() => {
                                    setimage3(null);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </span>
                              </label>
                            </>
                          ) : (
                            <span
                              className={`dropify-wrapper text-center ${
                                !image2 ? "bg-light text-disabled" : ""
                              }`}
                            >
                              <input
                                type="file"
                                name="image"
                                id="file3"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={!image2}
                                onChange={(event) => {
                                  setimage3(event);
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-upload"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                              </svg>
                              <label
                                htmlFor="file3"
                                className="pointer text-center"
                              >
                                Upload Image
                              </label>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!editMaterial && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Opening Stock {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        required
                        placeholder="Enter Opening Stock Qt."
                        value={openingStock}
                        onChange={(e) => setOpeningStock(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Low Stock {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Low Stock Qt."
                      required
                      value={lowStock}
                      // min={1}
                      onChange={(e) => setlowStock(e.target.value)}
                    />
                  </div>
                </div>
                {!editMaterial && (
                  <div className="col-sm-6">
                    <label className="text-dark fw-300 fs-6">
                      Total Price {reuiredField}
                    </label>
                    <input
                      className="form-control mt-2"
                      type="number"
                      // min="1"
                      required
                      placeholder="Enter Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                )}
                {!editMaterial && (
                  <div className="col-sm-6">
                    <label className="text-dark fw-300 fs-6">Brand name</label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      value={brandName}
                      placeholder="Enter Brand Name"
                      onChange={(e) => setBrandName(e.target.value)}
                    />
                  </div>
                )}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !unit ||
                    selectCategory === "Select Category" ||
                    billNumber === ""
                  }
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editMaterial === "" ? (
                    "Add"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
          {addUnitModal && (
            <AddRawMaterialUnit
              setAddUnitModal={setAddUnitModal}
              handleGetAllUnit={handleGetAllUnit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddRawMaterial;
