import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  convertTotalHoursToHoursAndMinutes,
  dateAndTimeFormatter,
  getTimeFormatter,
} from "../../../../global-functions/globalFunctions";
import { navigateToStaffAttendanceDetails } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

function AttendanceDaily(props) {
  const {
    dailyAttendanceData,
    setabsentModal,
    page,
    pageLimit,
    allCounts,
    setindividualStaffData,
    setIsOpen,
    dailyDateFilter,
    isModuleReadWriteAccessForAttendance,
    setlopModal,
  } = props;
  const navigate = useNavigate();
  let d = new Date(dailyDateFilter);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleAbsentClick = (data) => {
    setindividualStaffData(data);
    setabsentModal(true);
  };

  const AttendanceCell = ({ data, setindividualStaffData, setIsOpen }) => (
    <div
      onClick={() => {
        if (data?.attendances?.isVerified === false) {
          setindividualStaffData(data);
          setIsOpen(true);
        }
      }}
    >
      {data?.attendances?.isVerified ? (
        <span className="text-success fw-semibold pointer">Verified</span>
      ) : (
        <span className="text-primary fw-semibold pointer">Verify</span>
      )}
    </div>
  );

  const AttendanceStatusCell = ({
    isVerified,
    textVerified,
    textUnverified,
    setindividualStaffData,
    setIsOpen,
    setabsentModal,
    data,
  }) => (
    <div
      onClick={() => {
        if (isVerified === false && data?.attendances?.isLeave) {
          setindividualStaffData(data);
          setabsentModal(true);
        }
        // else if (
        //   isVerified === false &&
        //   data?.attendances?.presentStatus === "absent" &&
        //   !data?.attendances?.isLeave
        // ) {
        //   setindividualStaffData(data);
        //   setabsentModal(true);
        // }
      }}
    >
      {isVerified ? (
        <span className="text-success fw-semibold pointer">{textVerified}</span>
      ) : (
        <span className="text-primary fw-semibold pointer">
          {textUnverified}
        </span>
      )}
    </div>
  );

  return (
    <>
      <div className="d-flex gap-3">
        <div className="card py-2 px-4 text-center rounded-4">
          <p className="text-dark fs-6">Total Present</p>
          <p className="text-success">
            {allCounts?.totalPresent} / {allCounts?.totalStaff}
          </p>
        </div>
        <div className="card py-2 px-4 text-center rounded-4">
          <p className="text-dark fs-6">Total Absent</p>
          <p className="text-danger">
            {allCounts?.totalStaff - allCounts?.totalPresent} /{" "}
            {allCounts?.totalStaff}
          </p>
        </div>
      </div>
      <div className="table-responsive simple-scrollbar">
        <table id="datable_1" className="table table-bordered mb-8">
          <thead>
            <tr className="text-center text-nowrap">
              <th>No.</th>
              <th style={{ textAlign: "left" }}>Employee</th>
              <th>Attendance</th>
              <th>First Login</th>
              <th>Last Logout</th>
              <th>Sessions</th>
              <th>Total Hours</th>
              {isModuleReadWriteAccessForAttendance && <th>Verification</th>}
              {isModuleReadWriteAccessForAttendance && <th>Mark LOP</th>}
              <th>More Info</th>
            </tr>
          </thead>
          <tbody>
            {dailyAttendanceData?.map((data, index) => {
              const presentShift = data?.staff?.shiftTimings.find(
                (shift) => shift.shiftType === "present"
              );
              let lastLogout = "";
              let firstLogin = "";
              const firstSession = data?.attendances
                ? data?.attendances?.sessions[0]
                : "";
              const lastSession = data?.attendances
                ? data?.attendances.sessions[
                    data?.attendances?.sessions?.length - 1
                  ]
                : "";

              lastLogout = lastSession?.logoutTime;
              firstLogin = firstSession?.loginTime;
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td style={{ textAlign: "left" }}>
                    <div className="d-flex align-items-center gap-2">
                      <Avatar
                        name={data?.staff?.employeeId?.name}
                        count={1}
                        image={data?.staff?.employeeId?.image}
                        size="xs"
                        color="primary"
                      />
                      <div className="w-200p">
                        <p className="text-dark text-truncate text-nowrap">
                          {data?.staff?.employeeId?.name}
                        </p>
                        <p className="fs-8">
                          {data?.attendances?.staffShift
                            ? getTimeFormatter(
                                new Date(
                                  data?.attendances?.staffShift?.startTime
                                )
                              )
                            : presentShift
                            ? getTimeFormatter(presentShift?.shiftId?.startTime)
                            : ""}{" "}
                          -{" "}
                          {data?.attendances?.staffShift
                            ? getTimeFormatter(
                                new Date(data?.attendances?.staffShift?.endTime)
                              )
                            : presentShift
                            ? getTimeFormatter(presentShift?.shiftId?.endTime)
                            : ""}
                        </p>
                        {data?.staff?.employeeId?.permissions?.length === 0 && (
                          <span className="badge badge-sm badge-red">
                            Removed
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td
                    className={`text-capitalize ${
                      data?.attendances?.presentStatus === "present"
                        ? "text-success"
                        : data?.attendances?.presentStatus === "absent" &&
                          !data?.attendances?.isLeave
                        ? "text-orange"
                        : // : data?.attendances?.presentStatus === "half_day"
                          // ? "text-warning"
                          // : data?.attendances?.presentStatus === "absent" &&
                          //   data?.attendances?.isLeave
                          // ? "text-danger"
                          // : data?.staff?.offDays?.includes(daysOfWeek[d.getDay()])
                          // ? "text-violet"
                          ""
                    }`}
                  >
                    {data?.attendances?.presentStatus === "present"
                      ? "Present"
                      : data?.attendances?.presentStatus === "absent" &&
                        !data?.attendances?.isLeave
                      ? "Absent"
                      : data?.attendances?.presentStatus === "half_day"
                      ? "Half Day"
                      : data?.attendances?.presentStatus === "absent" &&
                        data?.attendances?.isLeave
                      ? "On Leave"
                      : data?.staff?.offDays?.includes(daysOfWeek[d.getDay()])
                      ? "Weekly Off"
                      : "-"}
                  </td>
                  <td>
                    {firstLogin
                      ? dateAndTimeFormatter(firstLogin)?.split(",")[1]?.trim()
                      : "-"}
                  </td>
                  <td>
                    {lastLogout
                      ? dateAndTimeFormatter(lastLogout)?.split(",")[1]?.trim()
                      : "-"}
                  </td>
                  <td>
                    {data?.attendances
                      ? data?.attendances?.sessions?.length
                      : "0"}
                    &nbsp;
                    {data?.attendances?.sessions.length > 0 && (
                      <>
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            fill="currentColor"
                            class="bi bi-caret-down-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                          </svg>
                        </i>
                        <div className="dropdown-menu">
                          <div className="dropdown-item w-240p">
                            <div className="row">
                              <p className="fs-5 text-primary fw-medium">
                                Activity
                              </p>
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="row">
                              <div className="nicescroll-bar d-flex flex-column simple-scrollbar px-0 py-1 mh-125p">
                                {data?.attendances?.sessions?.map(
                                  (activity) => {
                                    return (
                                      <>
                                        {activity?.loginTime && (
                                          <div className="d-flex gap-2 fs-7">
                                            <div>
                                              <p className="text-success">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-circle"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                </svg>
                                              </p>
                                              <span className="border-start ms-2 fs-3"></span>
                                            </div>
                                            <div className="text-dark">
                                              <p className="fw-medium">
                                                Punch in at
                                              </p>
                                              <p>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-clock"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>
                                                &nbsp;{" "}
                                                {dateAndTimeFormatter(
                                                  activity?.loginTime
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                        {activity?.logoutTime && (
                                          <div className="d-flex gap-2 fs-7">
                                            <div>
                                              <p className="text-success">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-circle"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                </svg>
                                              </p>
                                              <span className="border-start ms-2 fs-3"></span>
                                            </div>
                                            <div className="text-dark">
                                              <p className="fw-medium">
                                                Punch out at
                                              </p>
                                              <p>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-clock"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>
                                                &nbsp;
                                                {dateAndTimeFormatter(
                                                  activity?.logoutTime
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    {" "}
                    {data?.attendances
                      ? convertTotalHoursToHoursAndMinutes(
                          data?.attendances?.totalHours
                        )
                      : "0"}
                  </td>
                  {isModuleReadWriteAccessForAttendance && (
                    <td>
                      <>
                        {data?.attendances?.sessions?.length > 0 && (
                          <AttendanceCell
                            data={data}
                            setindividualStaffData={setindividualStaffData}
                            setIsOpen={setIsOpen}
                            setabsentModal={setabsentModal}
                          />
                        )}

                        {data?.attendances?.sessions?.length === 0 &&
                          !data?.attendances?.isLeave && (
                            <div className="text-success fw-semibold pointer">
                              Verified
                            </div>
                          )}
                        {!data?.attendances && (
                          <div
                            className="text-primary fw-semibold pointer"
                            onClick={() => handleAbsentClick(data)}
                          >
                            Verify
                          </div>
                        )}
                        {data?.attendances?.isLeave && (
                          <AttendanceStatusCell
                            isVerified={data?.attendances?.isVerified}
                            textVerified="Verified"
                            textUnverified="Verify"
                            setindividualStaffData={setindividualStaffData}
                            setIsOpen={setIsOpen}
                            setabsentModal={setabsentModal}
                            data={data}
                          />
                        )}
                        {/* {!data?.attendances?.sessions?.length &&
                        !data?.attendances?.isLeave &&
                        data?.attendances?.presentStatus === "absent" && (
                          <AttendanceStatusCell
                            isVerified={data?.attendances?.isVerified}
                            textVerified="Verified"
                            textUnverified="Verify"
                            setindividualStaffData={setindividualStaffData}
                            setIsOpen={setIsOpen}
                            setabsentModal={setabsentModal}
                            data={data}
                          />
                        )} */}
                      </>
                    </td>
                  )}
                  {isModuleReadWriteAccessForAttendance && (
                    <>
                      {data?.attendances?.presentStatus === "present" ? (
                        <td
                          className={`${
                            data?.attendances?.isLop
                              ? "text-success"
                              : "text-danger"
                          } pointer fw-bold`}
                          onClick={() => {
                            setindividualStaffData(data);
                            setlopModal(true);
                          }}
                        >
                          {" "}
                          {data?.attendances?.isLop
                            ? "Revoke LOP"
                            : "Apply LOP"}
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </>
                  )}
                  <td>
                    <span
                      className="btn btn-icon btn-rounded btn-flush-light"
                      onClick={() =>
                        navigate(
                          navigateToStaffAttendanceDetails({
                            id: data?.staff?.employeeId?._id,
                          }),
                          {
                            state: {
                              selectedDate: dailyDateFilter,
                            },
                          }
                        )
                      }
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-right-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                          />
                        </svg>
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AttendanceDaily;
