import React, { useEffect, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  handleUploadImgFile,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import { VendorRelatedEndPoints } from "../../../api/Endpoints";
import VendorAccountDetailsTable from "./VendorAccountDetailsTable";

const VendorDetailsInfo = ({
  setIsOpen,
  vendorDetails,
  setEditVendor,
  loader2,
  id,
  handleGetVendorDetails,
  selectTab,
  seteditAccountDetails,
}) => {
  const [image, setimage] = useState(null);
  const [isUploading, setisUploading] = useState(false);

  const setFileForUpload = (event) => {
    setimage(event);
  };

  const handleEditVendor = async () => {
    startApiCall(null, setisUploading);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      vendorId: id,
      image: uploadImg,
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.editVendor, data);
    if (res?.success) {
      setisUploading(false);
      toast.success("Profile Picture Updated Successfully");
      handleGetVendorDetails();
    } else {
      setisUploading(false);
    }
  };

  useEffect(() => {
    if (image) {
      handleEditVendor();
    }
  }, [image]);

  return (
    <div className="row">
      {loader2 && <DataLoader title="Data is loading..." />}
      {!loader2 && (
        <>
          {selectTab === "Profile" && (
            <div className="card rounded-0">
              <div className="card-body py-2 mb-3">
                <div className="row">
                  <div className="d-flex justify-content-end gap-2 mt-1">
                    <span
                      className="icon btn btn-icon btn-rounded btn-soft-primary btn-xs"
                      data-bs-toggle="modal"
                      data-bs-target="#add_vendor"
                      onClick={() => {
                        setEditVendor(vendorDetails);
                      }}
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>
                      </span>
                    </span>
                    <span
                      className="icon btn btn-icon btn-rounded btn-soft-danger btn-xs"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="image-container">
                      <span className="image-link pointer">
                        <img
                          src={
                            vendorDetails?.image
                              ? vendorDetails?.image
                              : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
                          }
                          alt=""
                          style={{
                            width: "148px",
                            height: "160px",
                            borderRadius: "10px",
                          }}
                        />
                        <span
                          className="position-absolute badge text-dark border bg-white circle d-flex align-items-center justify-content-center mnw-0 p-0"
                          style={{
                            height: "27px",
                            width: "27px",
                            top: "-10px",
                            right: "-10px",
                          }}
                        >
                          {isUploading && <span>uploading....</span>}
                          {!isUploading && (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="profilePhoto"
                                className="input-file"
                                accept="image/png,image/jpeg"
                                onChange={setFileForUpload}
                              />
                              <label
                                htmlFor="profilePhoto"
                                className="pointer text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg>
                              </label>
                            </>
                          )}
                        </span>
                      </span>
                    </div>
                    <div className="fw-bold ms-5 gap-3">
                      <h3 className="text-capitalize">{vendorDetails?.name}</h3>
                      <div className="flex-column">
                        <p className="mb-2 fw-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-telephone me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                          </svg>
                          Phone : {vendorDetails?.phone}
                        </p>
                        <p className="mb-2 fw-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-envelope me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                          </svg>
                          Email :{" "}
                          {vendorDetails?.email ? vendorDetails?.email : "-"}
                        </p>
                        <span className="d-flex mb-0 fw-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-person-plus me-2 mt-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                            <path
                              fill-rule="evenodd"
                              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                            />
                          </svg>
                          Created by : &nbsp;
                          <Avatar
                            name={vendorDetails?.createdBy?.name}
                            count={1}
                            image={vendorDetails?.createdBy?.image}
                            size="xss"
                            color="primary"
                          />
                          <p className="ms-2">
                            {vendorDetails?.createdBy?.name}
                          </p>
                        </span>
                        <p className="mb-2 fw-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-clock me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                          </svg>
                          Date Created :{" "}
                          {dateAndTimeFormatter(vendorDetails?.createdAt)}
                        </p>
                      </div>
                    </div>
                    <div className="ms-8">
                      <div className="d-flex flex-column gap-3">
                        <span>Shipping Address :</span>
                      </div>
                      {vendorDetails?.shippingAddress?.street === "" &&
                        vendorDetails?.shippingAddress?.city === "" &&
                        vendorDetails?.shippingAddress?.pinCode === "" &&
                        vendorDetails?.shippingAddress?.country === "" && (
                          <p>No Shipping Address</p>
                        )}
                      <p>{vendorDetails?.shippingAddress?.street}</p>
                      <p>{vendorDetails?.shippingAddress?.city}</p>
                      <p>{vendorDetails?.shippingAddress?.pinCode}</p>
                      <p>{vendorDetails?.shippingAddress?.country}</p>
                    </div>
                    <div className="ms-8">
                      <div className="d-flex flex-column gap-3">
                        <span>Billing Address :</span>
                      </div>
                      {vendorDetails?.billingAddress?.street === "" &&
                        vendorDetails?.billingAddress?.city === "" &&
                        vendorDetails?.billingAddress?.pinCode === "" &&
                        vendorDetails?.billingAddress?.country === "" && (
                          <p>No Billing Address</p>
                        )}
                      <p>{vendorDetails?.billingAddress?.street}</p>
                      <p>{vendorDetails?.billingAddress?.city}</p>
                      <p>{vendorDetails?.billingAddress?.pinCode}</p>
                      <p>{vendorDetails?.billingAddress?.country}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2 justify-content-start gap-3 mt-5">
                  <p className="card-title fw-semibold font-4">
                    Vendor Account details
                  </p>
                  <sapn className="ms-3">
                    <button
                      className="btn btn-sm btn-outline-primary flex-shrink-0 d-lg-inline-block"
                      data-bs-toggle="modal"
                      data-bs-target="#add_account_details"
                    >
                      Add Vendor Account
                    </button>
                  </sapn>
                  {/* <span
                    className="icon btn btn-icon btn-rounded btn-soft-primary btn-xs"
                    data-bs-toggle="modal"
                    data-bs-target="#add_account_details"
                  >
                    <span className="feather-icon">
                      {vendorDetails?.accountDetails?.holderName === null ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>
                      )}
                    </span>
                  </span> */}
                </div>
                {/* {vendorDetails?.accountDetails?.holderName !== null && (
                  <div className="fw-bold">
                    <p className="mb-2 fw-light">
                      Account Holder Name :{" "}
                      {vendorDetails?.accountDetails?.holderName}
                    </p>
                    <p className="mb-2 fw-light">
                      Account Number :{" "}
                      {vendorDetails?.accountDetails?.accountNumber}
                    </p>
                    <p className="mb-2 fw-light">
                      IFSC Code : {vendorDetails?.accountDetails?.ifscCode}
                    </p>
                  </div>
                )} */}
                {vendorDetails?.bankAccounts?.length > 0 && (
                  <VendorAccountDetailsTable
                    vendorDetails={vendorDetails}
                    seteditAccountDetails={seteditAccountDetails}
                  />
                )}

                {vendorDetails?.bankAccounts?.length === 0 && (
                  <div className="text-start ms-2 fs-5 text-bold text-capitalize">
                    <p className="py-2">Account not added</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VendorDetailsInfo;
