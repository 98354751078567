import React from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { navigateToTaskAnalysisDetails } from "../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

function TaskAnalysisBody({ allTaskOverview, setuserData, setactivityModal }) {
  const navigate = useNavigate();
  return (
    <div className="mt-4">
      <div className="d-flex flex-column gap-3">
        <div className="row">
          {allTaskOverview?.map((task) => {
            return (
              <div className="col-lg-4 col-md-6">
                <div className="card mb-4" style={{ background: "#EDFFF8" }}>
                  <div className="card-body d-flex flex-column gap-3">
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <Avatar
                          name={task?.employeeName}
                          count={1}
                          image={task?.employeeImage}
                          size="sm"
                          color="primary"
                        />
                        <div>
                          <p className="mb-0 h5 fw-semibold">
                            {task?.employeeName}
                          </p>
                          {task?.permissions?.length === 0 && (
                            <span className="badge badge-sm badge-red">Removed</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0 fs-6 text-dark d-flex align-items-center gap-1 text-capitalize">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#FFC207"
                          class="bi bi-star-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        {task?.permissions?.[0]?.roleName}
                      </p>
                      <div className="d-flex flex-column">
                        {" "}
                        <p
                          className="text-primary fs-8 text-link text-decoration-underline mb-1"
                          onClick={() =>
                            navigate(
                              navigateToTaskAnalysisDetails({
                                id: task?.employeeId,
                              }),
                              {
                                state: {
                                  name: task?.employeeName,
                                  image: task?.employeeImage,
                                },
                              }
                            )
                          }
                          // data-bs-toggle="modal"
                          // data-bs-target="#employeeActivityModal"
                        >
                          View Full Report
                        </p>
                        <p
                          className="mb-0 text-primary fs-8 text-link text-decoration-underline"
                          onClick={() => {
                            setuserData(task);
                            setactivityModal(true);
                          }}
                        >
                          View All Task
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex justify-content-between">
                          <p className="text-dark">Open</p>
                          <p>
                            {" "}
                            {task?.dataType === "analytics"
                              ? task?.statuses?.active
                              : 0}
                          </p>
                        </div>
                        <div
                          className="progress rounded-4"
                          style={{
                            height: "10px",
                            background: "#e1e1e1",
                          }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width:
                                task?.dataType === "analytics"
                                  ? `${
                                      (task?.statuses?.active /
                                        task?.statuses?.all) *
                                      100
                                    }%`
                                  : 0,
                            }}
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex justify-content-between">
                          <p className="text-dark">Done</p>
                          <p>
                            {task?.dataType === "analytics"
                              ? task?.statuses?.done
                              : 0}
                          </p>
                        </div>
                        <div
                          className="progress rounded-4"
                          style={{
                            height: "10px",
                            background: "#e1e1e1",
                          }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width:
                                task?.dataType === "analytics"
                                  ? `${
                                      (task?.statuses?.done /
                                        task?.statuses?.all) *
                                      100
                                    }%`
                                  : 0,
                            }}
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex justify-content-between">
                          <p className="text-dark">Completed</p>
                          <p>
                            {task?.dataType === "analytics"
                              ? task?.statuses?.completed
                              : 0}
                          </p>
                        </div>
                        <div
                          className="progress rounded-4"
                          style={{
                            height: "10px",
                            background: "#e1e1e1",
                          }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width:
                                task?.dataType === "analytics"
                                  ? `${
                                      (task?.statuses?.completed /
                                        task?.statuses?.all) *
                                      100
                                    }%`
                                  : 0,
                            }}
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TaskAnalysisBody;
