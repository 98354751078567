import React, { useEffect, useState } from "react";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import {
  dateAndTimeFormatter,
  getName,
  startApiCall,
  getCurrentWorkspaceId,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { CrmParticipantsGroup } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import { Avatar } from "../../../../components/Avatar/Avatar";
import NoData from "../../../../components/NoData/NoData";
import ChangeRoleModal from "./ChangeRoleModal";

const ParticipantTable = ({
  loader,
  id,
  handleGetAllParticipants,
  groupDetails,
  allGroupMembers,
  user,
  isModuleReadWriteAccessForParticipantGroup,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [empId, setEmpId] = useState("");

  const [isOpenDefault, setisOpenDefault] = useState(false);
  const [isOpenChangeRole, setisOpenChangeRole] = useState(false);
  const [selectedRole, setselectedRole] = useState("");

  const currentWorkspaceId = getCurrentWorkspaceId();

  useEffect(() => {
    if (empId?.role) {
      setselectedRole(empId?.role);
    }
  }, [empId]);

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader2(false);
  };

  const handleCloseDefault = () => {
    setisOpenDefault(false);
    setErrorMessage("");
    setloader2(false);
  };

  const handleCloseChangeRole = () => {
    setisOpenChangeRole(false);
    setErrorMessage("");
    setloader2(false);
  };

  const handleRemoveParticipant = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader2);
    const data = {
      leadGroupId: id,
      employees: [empId?._id],
      actionBy: user?._id,
      isAdded: false,
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.addParticipant,
      data
    );
    if (res?.success) {
      handleClose();
      handleGetAllParticipants();
      toast.success("Remove Participants successfully!");
    } else {
      setloader2(false);
      setErrorMessage(res.error);
    }
  };

  const handleAddAsDefaultAssignee = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader2);
    const data = {
      participantsGroupId: id,
      employeeId: empId?._id,
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.addDefaultAssignee,
      data
    );
    if (res?.success) {
      handleGetAllParticipants();
      toast.success("Add as default assignee successfully!");
      handleCloseDefault();
    } else {
      setloader2(false);
      setErrorMessage(res.error);
    }
  };
  const handleChangeRole = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader2);
    const data = {
      participantId: empId?._id,
      previousRole: empId?.role,
      newRole: selectedRole,
      participantsGroupId: id,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.changeRoleParticipant,
      data
    );
    if (res?.success) {
      handleGetAllParticipants();
      toast.success("Add as default assignee successfully!");
      handleCloseChangeRole();
    } else {
      setloader2(false);
      setErrorMessage(res.error);
    }
  };

  const checkIsEmp = () => {
    let a = allGroupMembers.filter(
      (item) => item?._id === user?._id && item?.role === "employee"
    );
    return a?.length ? true : false;
  };

  return (
    <>
      {loader && <DataLoader title="data is loading..." />}
      {!loader && groupDetails && (
        <div
          className="overflow-y-scroll simple-scrollbar"
          style={{ maxHeight: "65vh" }}
        >
          <table id="datable_1" className="table nowrap w-100 mb-5">
            <thead>
              <tr className="text-center">
                <th>No .</th>
                <th style={{ textAlign: "left" }}>Name</th>
                <th>Role</th>
                {isModuleReadWriteAccessForParticipantGroup && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {allGroupMembers?.map((data, index) => {
                return (
                  <tr className="text-center">
                    <td>{index + 1} .</td>
                    <td style={{ textAlign: "left" }}>
                      <Avatar
                        name={data?.name}
                        count={1}
                        image={data?.image}
                        size="xss"
                        color="primary"
                      />
                      <span className="ms-1">{data?.name}</span>
                      &nbsp;
                      {data?._id === groupDetails?.defaultAssignee && (
                        <span className="badge badge-sm badge-primary ms-auto">
                          default
                        </span>
                      )}
                      &nbsp;
                      {!data?.permissions?.some(
                        (item) => item?.businessId === currentWorkspaceId
                      ) && (
                        <span className="badge badge-sm badge-red">
                          Removed
                        </span>
                      )}
                    </td>
                    <td className="text-capitalize">
                      {data?.role === "admins"
                        ? "admin"
                        : data?.role === "managers"
                        ? "manager"
                        : "member"}
                    </td>
                    {isModuleReadWriteAccessForParticipantGroup && (
                      <td>
                        {!checkIsEmp() ? (
                          <span className="feather-icon pointer">
                            <i
                              data-bs-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="ri-more-fill"
                            ></i>
                            <div className="dropdown-menu">
                              <div
                                className="dropdown-item d-flex align-items-center"
                                onClick={() => {
                                  setEmpId(data);
                                  setisOpenDefault(true);
                                }}
                              >
                                <span
                                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                >
                                  <span className="btn-icon-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="23"
                                      height="20"
                                      fill="currentColor"
                                      class="bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      />
                                    </svg>
                                  </span>
                                </span>
                                <p>Make as Default Assigner</p>
                              </div>
                              <div
                                className="dropdown-item d-flex align-items-center"
                                onClick={() => {
                                  setEmpId(data);
                                  setisOpenChangeRole(true);
                                }}
                              >
                                <span
                                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                >
                                  <span className="btn-icon-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-bag-x-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M6.854 8.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293z"
                                      />
                                    </svg>
                                  </span>
                                </span>
                                <p>Change Role</p>
                              </div>
                              {data?.role !== "admins" && (
                                <div
                                  className="dropdown-item d-flex align-items-center"
                                  onClick={() => {
                                    setEmpId(data);
                                    setIsOpen(true);
                                  }}
                                >
                                  <span
                                    className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="btn-icon-wrap">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-x-circle"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                      </svg>
                                    </span>
                                  </span>
                                  <p>Remove</p>
                                </div>
                              )}
                            </div>
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {!loader && !groupDetails && (
        <NoData title="No participants added to the group." />
      )}
      {isOpen && (
        <ConfirmModal
          isOpen={isOpen}
          onClose={handleClose}
          text={`Are you sure you want to remove ${empId?.name} ?`}
          loader={loader2}
          errorMessage={errorMessage}
          handleConfirm={handleRemoveParticipant}
        />
      )}

      {isOpenDefault && (
        <ConfirmModal
          isOpen={isOpenDefault}
          onClose={handleCloseDefault}
          text={`Are you sure you want to add this participant as default assignee?`}
          loader={loader2}
          errorMessage={errorMessage}
          handleConfirm={handleAddAsDefaultAssignee}
        />
      )}

      {isOpenChangeRole && (
        <ChangeRoleModal
          isOpen={isOpenChangeRole}
          onClose={handleCloseChangeRole}
          loader={loader2}
          errorMessage={errorMessage}
          handleConfirm={handleChangeRole}
          setselectedRole={setselectedRole}
          selectedRole={selectedRole}
        />
      )}
    </>
  );
};

export default ParticipantTable;
