import React from "react";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import { getName } from "../../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import CustomTooltip from "../../../../../components/CustomTooltip/CustomTooltip";

function TimelineMoreInfoModal({
  setmoreInfoModal,
  timelineDetails,
  setimageData,
  setimageModal,
}) {
  const user = useSelector((state) => state.user);

  const closeModal = () => {
    setmoreInfoModal(false);
  };

  const stripHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "-";
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered w-700p  order-confirm-modal"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={closeModal}>
              <span aria-hidden="true">×</span>
            </button>
            <h5 class="text-capitalize mb-2">Timeline Other Info</h5>

            <div className="border-bottom mb-3"></div>
            <div
              className="d-flex flex-column gap-1 overflow-auto simple-scrollbar"
              style={{ height: "60vh" }}
            >
              <p className="font-4 text-capitalize">
                Action : &nbsp;
                <span className="text-dark">
                  {timelineDetails?.description}
                </span>
              </p>
              {timelineDetails?.action === "assign group" && (
                <p className="font-4 text-capitalize mt-1 ">
                  Assigned Group Names : &nbsp;
                  <br />
                  {timelineDetails?.moreInfo?.map((item, index) => {
                    return (
                      <span className="text-dark mt-2  d-flex">
                        {index + 1}. {item?.groupName}
                        <br />
                      </span>
                    );
                  })}
                </p>
              )}
              {timelineDetails?.action?.includes("status") && (
                <>
                  <p className="font-4 text-capitalize">
                    Prev Status : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.prevStatus}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    New Status : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.newStatus}
                    </span>
                  </p>
                </>
              )}
              {timelineDetails?.description === "activity status updated" && (
                <>
                  <p className="font-4 text-capitalize">
                    Activity Title : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.title}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    Activity Type : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.taskType}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    Prev Status : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.prevStatus}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    New Status : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.newStatus}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    Activity From : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.actionFromRef} (
                      {timelineDetails?.moreInfo?.[0]?.actionFromRef ===
                      "Status"
                        ? "Auto"
                        : "Manual"}
                      )
                    </span>
                  </p>
                </>
              )}
              {timelineDetails?.description === "activity created" && (
                <>
                  <p className="font-4 text-capitalize">
                    Activity Title : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.title}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    Activity Type : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.taskType}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    Activity From : &nbsp;
                    <span className="text-dark">
                      {timelineDetails?.moreInfo?.[0]?.actionFromRef} (
                      {timelineDetails?.moreInfo?.[0]?.actionFromRef ===
                      "Status"
                        ? "Automated"
                        : "Manual"}
                      )
                    </span>
                  </p>
                </>
              )}
              {timelineDetails?.action === "assign participant" && (
                <p className="font-4 text-capitalize mt-1 ">
                  Assignees : &nbsp;
                  <br />
                  {timelineDetails?.updatedFor?.map((item, index) => {
                    console.log("item : ", item)
                    return (
                      <span className="text-dark mt-3  d-flex">
                        {index + 1}.&nbsp;
                        <Avatar
                          name={item?.name}
                          count={1}
                          image={item?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(item, user?._id)}
                        &nbsp;
                        {/* {!data?.permissions?.some((item) => {
                          return item?.businessId?._id === currentWorkspaceId;
                        }) && (
                          <span className="badge badge-sm badge-red">
                            Removed
                          </span>
                        )} */}
                        <br />
                      </span>
                    );
                  })}
                </p>
              )}
              {timelineDetails?.description === "mail sent" && (
                <>
                  <p className="font-4 text-capitalize">
                    Email Details : &nbsp;
                    <span className="text-dark">
                      {stripHTML(timelineDetails?.emailBody)}
                    </span>
                  </p>
                  <p className="font-4 text-capitalize">
                    Email Attachment : &nbsp;
                    <span className="text-dark font-3">
                      {timelineDetails?.attachment?.map((att, index) => (
                        <div className="d-flex mt-2 gap-3 ">
                          <div className="d-flex align-items-center gap-2">
                            {index + 1}.{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="34"
                              height="34"
                              fill="currentColor"
                              class="bi bi-file-earmark-pdf"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                              <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                            </svg>
                            <span className="w-100 text-truncate">
                              {att?.name}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            {att?.name?.includes("png") && (
                              <CustomTooltip text="View Image" placement="left">
                                <span
                                  className="btn btn-icon btn-xs btn-primary"
                                  style={{
                                    width: "35px",
                                    height: "15px",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => {
                                    setimageData(att?.url);
                                    setimageModal(true);
                                  }}
                                >
                                  <span className="feather-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-eye"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                  </span>
                                </span>
                              </CustomTooltip>
                            )}

                            <CustomTooltip
                              text="Download Image"
                              placement="left"
                            >
                              <a href={att?.url} download={att?.name}>
                                <span
                                  className="btn btn-icon btn-xs rounded-circle btn-primary"
                                  style={{
                                    width: "35px",
                                    height: "15px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <span className="feather-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-download"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                    </svg>
                                  </span>
                                </span>
                              </a>
                            </CustomTooltip>
                          </div>
                        </div>
                      ))}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelineMoreInfoModal;
