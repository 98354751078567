import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToLeadsOverviewDetails } from "../../../Navigation/Navigation";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  getName,
} from "../../../global-functions/globalFunctions";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import { useSelector } from "react-redux";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { toast } from "react-toastify";

const LeadsTable = ({
  allLeads,
  page,
  pageLimit,
  countOverview,
  setCheckedLeads,
  checkedLeads,
  showCheckBox,
  selectAllChecked,
  setSelectAllChecked,
  loader,
  startDate,
  endDate,
  location,
}) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const currentWorkspaceId = getCurrentWorkspaceId();

  const totalCheckedLeads = Object?.values(checkedLeads)?.reduce(
    (total, pageCheckedLeads) => total + pageCheckedLeads?.length,
    0
  );

  const handleLeadCheckboxChange = (group) => {
    const currentPageCheckedGroups = checkedLeads[page] || [];
    const groupIndex = currentPageCheckedGroups.indexOf(group._id);

    if (groupIndex === -1 && totalCheckedLeads >= 800) {
      toast.warning("You cannot select more than 800 leads.");
      return;
    }

    if (groupIndex === -1) {
      const newCheckedGroups = {
        ...checkedLeads,
        [page]: [...currentPageCheckedGroups, group._id],
      };
      setCheckedLeads(newCheckedGroups);
    } else {
      const newCheckedGroups = {
        ...checkedLeads,
        [page]: [
          ...currentPageCheckedGroups.slice(0, groupIndex),
          ...currentPageCheckedGroups.slice(groupIndex + 1),
        ],
      };
      setCheckedLeads(newCheckedGroups);
    }
  };

  const handleSelectAllCheckboxChange = () => {
    let newCheckedGroups = { ...checkedLeads };
    let newSelectAllChecked = { ...selectAllChecked };
    const currentPageCheckedGroups = checkedLeads[page] || [];

    if (newSelectAllChecked[page]) {
      newCheckedGroups[page] = [];
      newSelectAllChecked = { ...newSelectAllChecked, [page]: false };
    } else {
      const potentialTotalCheckedLeads =
        totalCheckedLeads + allLeads.length - currentPageCheckedGroups.length;

      if (potentialTotalCheckedLeads > 800) {
        toast.warning(
          "Selecting all leads on this page would exceed the 800 leads limit. You cannot select more than 800 leads"
        );
        return;
      }
      newCheckedGroups[page] = allLeads.map((grp) => grp._id);
      newSelectAllChecked = { ...newSelectAllChecked, [page]: true };
    }

    setCheckedLeads(newCheckedGroups);
    setSelectAllChecked(newSelectAllChecked);
  };

  useEffect(() => {
    const currentPageCheckedGroups = checkedLeads[page] || [];
    const allGroupsOnPageChecked = allLeads.every((grp) =>
      currentPageCheckedGroups.includes(grp._id)
    );

    setSelectAllChecked((prevSelectAllChecked) => ({
      ...prevSelectAllChecked,
      [page]: allGroupsOnPageChecked,
    }));
  }, [page, allLeads, checkedLeads]);

  const totalSelectedLeads = Object.values(checkedLeads).reduce(
    (total, array) => total + array.length,
    0
  );

  return (
    <div className="contact-list-view">
      <div className="col-12">
        <div className="card justify-content-between rounded-10 h-100 p-4 shadow-none">
          <p className="fs-6 text-dark fw-semibold">Lead overview</p>
          <div className="row gx-3 gy-2">
            <div className="col-md-3 col-sm-6">
              <div className="d-flex align-items-center gap-3">
                <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="blue"
                      className="bi bi-opencollective"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-opacity=".4"
                        d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"
                      />
                      <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z" />
                    </svg>
                  </span>
                </span>
                <div>
                  <p className="mb-0 fw-light">Total Leads</p>
                  <p className="mb-0 fs-5 text-primary fw-bold">
                    {!loader ? (
                      countOverview?.closeLostLead +
                      countOverview?.closeWonLead +
                      countOverview?.openLead +
                      countOverview?.pending
                    ) : (
                      <SkeletonConstant width="w-80" height="h-25p" />
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="d-flex align-items-center gap-3">
                <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1944c2"
                      class="bi bi-clock-history"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </span>
                </span>
                <div>
                  <p className="mb-0 fw-light">Total Open / Pending</p>
                  <p
                    className="mb-0 fs-5 fw-bold font-mon"
                    style={{ color: "#1944c2" }}
                  >
                    {!loader ? (
                      <>{countOverview?.openLead + countOverview?.pending}</>
                    ) : (
                      <SkeletonConstant width="w-80" height="h-25p" />
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="d-flex align-items-center gap-3">
                <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="green"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </span>
                </span>
                <div>
                  <p className="mb-0 fw-light">Total Close Won</p>
                  <p className="mb-0 fs-5 fw-bold font-mon text-success">
                    {!loader ? (
                      countOverview?.closeWonLead
                    ) : (
                      <SkeletonConstant width="w-80" height="h-25p" />
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="d-flex align-items-center gap-3">
                <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="red"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                  </span>
                </span>
                <div>
                  <p className="mb-0 fw-light">Total Close Lost</p>
                  <p className="mb-0 fs-5 fw-bold font-mon text-danger">
                    {!loader ? (
                      countOverview?.closeLostLead
                    ) : (
                      <SkeletonConstant width="w-80" height="h-25p" />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {loader && (
          <div className="card justify-content-between rounded-10 h-100 p-4 loading-skeleton">
            <SkeletonConstant width="w-120p" height="h-25p" />
            <div className="row gx-3 gy-2">
              <div className="col-6">
                <SkeletonConstant width="w-80" height="h-50p" />
              </div>
              <div className="col-6">
                <SkeletonConstant width="w-80" height="h-50p" />
              </div>
              <div className="col-6">
                <SkeletonConstant width="w-80" height="h-50p" />
              </div>
              <div className="col-6">
                <SkeletonConstant width="w-80" height="h-50p" />
              </div>
            </div>
          </div>
        )} */}
      </div>
      {totalSelectedLeads !== 0 && (
        <span className="text-primary">
          Total Selected Leads{" "}
          <span className="badge badge-pill badge-soft-primary">
            {totalSelectedLeads}
          </span>
        </span>
      )}
      <table
        id="datable_1"
        className="table nowrap w-100 mb-8 text-center fs-7"
      >
        <thead>
          <tr>
            <th>Sr No .</th>
            <th className="text-start ps-1">
              {showCheckBox && (
                <input
                  type="checkbox"
                  className="form-check-input pointer"
                  onChange={handleSelectAllCheckboxChange}
                  checked={selectAllChecked[page]}
                />
              )}
              &nbsp; Lead Title
            </th>
            <th className="px-1">Contact</th>
            <th>Source</th>
            <th className="px-1">Status Group</th>
            <th>Current Status</th>
            <th>Assigned Member</th>
            <th className="px-1 text-start">Added By</th>
            <th>Last Activity</th>
            <th>Last Status Updated</th>
          </tr>
        </thead>
        <tbody>
          {allLeads?.map((lead, index) => {
            const lastAction =
              lead?.leadLifeCycle?.[lead?.leadLifeCycle?.length - 1]
                ?.description;
            return (
              <tr>
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td className="text-start ps-1 ">
                  <div className="text-capitalize  d-flex">
                    {showCheckBox && (
                      <input
                        type="checkbox"
                        className="form-check-input pointer"
                        value={lead._id}
                        onChange={() => handleLeadCheckboxChange(lead)}
                        checked={(checkedLeads[page] || []).includes(lead._id)}
                      />
                    )}
                    &nbsp;&nbsp;
                    <div
                      className="text-link text-truncate mw-150p overflow-hidden"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flexShrink: 1,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          navigateToLeadsOverviewDetails({
                            id: lead?._id,
                          }),
                          {
                            state: {
                              startDate: startDate,
                              endDate: endDate,
                              status: location?.state?.status,
                              subStatus: location?.state?.subStatus,
                              _id: location?.state?._id,
                              name: location?.state?.name,
                              page: page,
                            },
                          }
                        )
                      }
                    >
                      {lead?.title}
                    </div>
                  </div>
                </td>
                <td className="px-1">
                  {lead?.countryCode && ` + ${lead?.countryCode}`}
                  {lead?.phone}
                </td>
                <td>{lead?.source}</td>
                <td className="px-1">{lead?.groupId?.name}</td>
                <td>
                  <span
                    className="badge badge-md ms-auto w-100"
                    style={{
                      backgroundColor: `${
                        lead?.initialStatusId?.color || "blue"
                      }`,
                    }}
                  >
                    {lead?.subStatusId?.name}
                  </span>
                </td>
                <td>
                  {lead?.assignedTo?.length > 0 ? (
                    <>
                      {lead?.assignedTo?.length === 1 ? (
                        <>
                          <Avatar
                            name={lead?.assignedTo?.[0]?.name}
                            count={1}
                            image={lead?.assignedTo?.[0]?.image}
                            size="xss"
                            color="primary"
                          />
                          &nbsp;{getName(lead?.assignedTo?.[0], user?._id)}
                          &nbsp;
                          {!lead?.assignedTo?.[0]?.permissions?.some(
                            (item) => item?.businessId === currentWorkspaceId
                          ) && (
                            <span className="badge badge-sm badge-red">
                              Removed
                            </span>
                          )}
                        </>
                      ) : (
                        <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer font-2">
                          {lead?.assignedTo?.slice(0, 3)?.map((emp) => {
                            return (
                              <CustomTooltip
                                text={emp?.name}
                                placement="bottom"
                              >
                                <Avatar
                                  name={emp?.name}
                                  count={1}
                                  image={emp?.image}
                                  size="xxs"
                                  color="primary"
                                />
                              </CustomTooltip>
                            );
                          })}
                          {lead?.assignedTo?.length > 3 && (
                            <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                              <span className="initial-wrap">
                                +{lead?.assignedTo?.length - 3}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    "No Assignee"
                  )}
                </td>
                <td className="px-1 text-start">
                  <Avatar
                    name={lead?.createdBy?.name}
                    count={1}
                    image={lead?.createdBy?.image}
                    size="xss"
                    color="primary"
                  />
                  <span className="ms-1">{lead?.createdBy?.name}</span>
                </td>
                <td className="text-capitalize">{lastAction}</td>
                <td className={`text-capitalize`}>
                  {dateAndTimeFormatter(lead?.statusUpdateTime)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeadsTable;
