import React from "react";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import { dateAndTimeFormatter } from "../../../../../global-functions/globalFunctions";

function ContactInfoCard({
  selectTab,
  isUploading,
  handleAddAttachment,
  contactDetails,
  handleRemoveAttachment,
  setEditContactData,
  dealCount,
  setShowDealDetails,
}) {
  return (
    <>
      <div className="d-flex flex-column gap-2 h-420p overflow-y-scroll simple-scrollbar">
        {selectTab === "Attachment" && (
          <>
            <div className="d-flex align-items-center justify-content-between ps-3 mt-2">
              <p className="text-dark fs-5">Attachment</p>
              <div className="d-flex align-items-end gap-3">
                {!isUploading ? (
                  <>
                    <input
                      type="file"
                      name="image"
                      id="profilePhoto"
                      accept="/*"
                      className="input-file justify-content-end"
                      onChange={handleAddAttachment}
                    />
                    <label
                      htmlFor={isUploading ? "" : "profilePhoto"}
                      className="pointer text-center"
                    >
                      <span className="btn btn-sm btn-outline-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          width="14"
                          height="14"
                          className="me-1"
                        >
                          <path d="M14.8287 7.75737L9.1718 13.4142C8.78127 13.8047 8.78127 14.4379 9.1718 14.8284C9.56232 15.219 10.1955 15.219 10.586 14.8284L16.2429 9.17158C17.4144 8.00001 17.4144 6.10052 16.2429 4.92894C15.0713 3.75737 13.1718 3.75737 12.0002 4.92894L6.34337 10.5858C4.39075 12.5384 4.39075 15.7042 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4142L14.8287 19.0711C12.095 21.8047 7.66283 21.8047 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17158L10.586 3.51473C12.5386 1.56211 15.7045 1.56211 17.6571 3.51473C19.6097 5.46735 19.6097 8.63317 17.6571 10.5858L12.0002 16.2427C10.8287 17.4142 8.92916 17.4142 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34316L14.8287 7.75737Z"></path>
                        </svg>{" "}
                        Attach
                      </span>
                    </label>
                  </>
                ) : (
                  <span className="btn btn-sm btn-outline-light">
                    please wait..
                  </span>
                )}
              </div>
            </div>
            {isUploading && (
              <span>Please wait until attachment finishes uploading...</span>
            )}
            {contactDetails?.attachment?.length === 0 && !isUploading && (
              <span className="ms-3 mt-2"> No Attachments.</span>
            )}
            <div className="d-flex justify-content-start flex-wrap gap-3 p-2 mt-1 ps-3">
              {contactDetails?.attachment?.map((att, index) => {
                return (
                  <>
                    {/* ********** IMAGE VIEW **********  */}
                    {/* {att?.type?.includes("image") && ( */}
                    <div className="w-120p text-center shadow-hover p-1 border d-flex flex-column gap-1">
                      <div className="w-100 h-60p d-flex align-items-center justify-content-center p-2 border">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="currentColor"
                          className="bi bi-file-earmark"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                        </svg>
                      </div>
                      <p className="text-dark fs-7 text-truncate mw-150p">
                        {att?.name}
                      </p>
                      <div className="d-flex align-items-center justify-content-between m-1">
                        <a
                          href={att?.url}
                          download
                          className="btn btn-sm btn-rounded btn-icon btn-outline-light"
                        >
                          <span title="Download File" className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </span>
                        </a>
                        <span className="btn btn-sm btn-rounded btn-icon btn-outline-danger">
                          <span
                            onClick={() => {
                              if (!isUploading) {
                                handleRemoveAttachment(att);
                              } else {
                                //NO CLICK
                              }
                            }}
                            title="Delete File"
                            className="feather-icon"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}

        {/* About section */}
        {selectTab === "Personal info" && (
          <>
            <div
              className="d-flex w-100 justify-content-end pointer"
              data-bs-toggle="modal"
              data-bs-target="#create_new_contact"
            >
              <span
                className="btn btn-sm btn-outline-primary"
                onClick={() => {
                  setEditContactData(contactDetails);
                }}
              >
                {" "}
                Edit&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </span>
            </div>
            <div className="d-flex flex-column px-4 gap-1">
              <p className="text-capitalize mt-2">
                1. Contact Name : &nbsp;
                <span className="text-dark">{contactDetails?.name}</span>
              </p>
              <p className="text-capitalize mt-2">
                2. Contact Description : &nbsp;
                <span className="text-dark">
                  {contactDetails?.description
                    ? contactDetails?.description
                    : "NA"}
                </span>
              </p>

              <p className="text-capitalize mt-2">
                3. Date Created : &nbsp;
                <span className="text-dark">
                  {dateAndTimeFormatter(contactDetails?.createdAt)}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                4. Contact Creator : &nbsp;
                <span className="text-dark">
                  <Avatar
                    name={contactDetails?.createdBy?.name}
                    count={1}
                    image={contactDetails?.createdBy?.image}
                    size="xss"
                    color="primary"
                  />
                  <span className="ms-1">
                    {contactDetails?.createdBy?.name}
                  </span>
                </span>
              </p>
              <p className="text-capitalize mt-2">
                5. Last Updated : &nbsp;
                <span className="text-dark">
                  {dateAndTimeFormatter(contactDetails?.updatedAt)}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                6. Total deals : <span className="text-dark">{dealCount}</span>
                {dealCount !== 0 && (
                  <span
                    className="text-primary pointer"
                    onClick={() => setShowDealDetails(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      fill="currentColor"
                      className="bi bi-info-circle ms-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </span>
                )}
              </p>
              <p className="text-capitalize mt-2">
                7. Company :{" "}
                <span className="text-dark">
                  {contactDetails?.businessName
                    ? contactDetails?.businessName
                    : "NA"}
                </span>
              </p>
              <p className="mt-2">
                8. Website Link:{" "}
                <span className="text-primary">
                  {contactDetails?.websiteLink
                    ? contactDetails?.websiteLink
                    : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                9. No of Employees : &nbsp;
                <span className="text-dark">
                  {contactDetails?.numberOfEmployees
                    ? contactDetails?.numberOfEmployees
                    : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                10. Annual Revenue : &nbsp;
                <span className="text-dark">
                  {contactDetails?.annualRevenue
                    ? contactDetails?.annualRevenue
                    : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                11. Industry : &nbsp;
                <span className="text-dark">
                  {contactDetails?.industry ? contactDetails?.industry : "NA"}
                </span>
              </p>
              {contactDetails?.additionalInfo?.length > 0 &&
                contactDetails?.additionalInfo?.map((fld, index) => {
                  const isValidURL = (string) => {
                    try {
                      new URL(string);
                      return true;
                    } catch (_) {
                      return false;
                    }
                  };

                  return (
                    <p className="mt-2" key={index}>
                      {16 + index} . {fld?.fieldName} : &nbsp;
                      <span className="text-dark">
                        {fld?.fieldValue ? (
                          isValidURL(fld?.fieldValue) ? (
                            <a
                              href={fld?.fieldValue}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {fld?.fieldValue}
                            </a>
                          ) : (
                            fld?.fieldValue
                          )
                        ) : (
                          "NA"
                        )}
                      </span>
                    </p>
                  );
                })}
            </div>
          </>
        )}

        {/* Contact section */}
        {selectTab === "Contact info" && (
          <>
            <div className="d-flex flex-column px-4 gap-1">
              <p className="text-capitalize mt-2">
                1. Phone Number :{" "}
                <span className="text-dark">
                  {contactDetails?.countryCode &&
                    ` + ${contactDetails?.countryCode}`}
                  {contactDetails?.phone ? contactDetails?.phone : "-"}
                </span>
              </p>
              <p className="mt-2">
                2. Email Address :{" "}
                <span className="text-dark">
                  {contactDetails?.email ? contactDetails?.email : "-"}
                </span>
              </p>
              <p className="mt-2">
                3. State :{" "}
                <span className="text-dark">
                  {contactDetails?.state ? contactDetails?.state : "-"}
                </span>
              </p>
              <p className="mt-2">
                4. City :{" "}
                <span className="text-dark">
                  {contactDetails?.city ? contactDetails?.city : "-"}
                </span>
              </p>
              <p className="mt-2">
                5. Pincode :{" "}
                <span className="text-dark">
                  {contactDetails?.pinCode ? contactDetails?.pinCode : "-"}
                </span>
              </p>
              <p className="mt-2">
                6. Address :{" "}
                <span className="text-dark">
                  {contactDetails?.address ? contactDetails?.address : "-"}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ContactInfoCard;
