import React, { useEffect, useState } from "react";
import EmployeeCrmCountsTable from "./EmployeeCrmCountsTable";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import EmployeeCrmCountHeader from "./EmployeeCrmCountHeader";

function EmployeeCrmCounts() {
  const { allModuleAccess, user } = useSelector((state) => state);
  const [loader, setLoader] = useState(true);
  const [empAnalytics, setempAnalytics] = useState([]);
  const [leadStatusGroup, setleadStatusGroup] = useState("");
  const [allStatusGroup, setallStatusGroup] = useState(null);
  const [loaderGroup, setLoaderGroup] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [initialStatuses, setInitialStatuses] = useState("");
  const [selectedInitialStatus, setselectedInitialStatus] = useState(null);
  const [selectedInitialStatusName, setselectedInitialStatusName] =
    useState("");
  const [allSubStatus, setallSubStatus] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setstartDate] = useState(today);
  const [endDate, setendDate] = useState(today);

  const isModuleReadWriteAccessForEmployeeAnalytics =
    allModuleAccess?.[PRIVATEROUTES.Employee_Analytics];

  // GET All EMPLOYEE WISE CRM COUNT
  const handleGetAllEmployeeCount = async () => {
    startApiCall(null, setLoader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        initialStatusId: selectedInitialStatus,
        startDate: startDate,
        endDate: endDate,
      },
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.crmEmpAnalytics,
      data
    );
    if (res?.success) {
      const filteredEmployee = res?.result?.results?.filter(
        (emp) => emp?.employeeId === user?._id
      );
      const unFilteredEmployee = res?.result.results;
      setempAnalytics(
        isModuleReadWriteAccessForEmployeeAnalytics
          ? unFilteredEmployee
          : filteredEmployee
      );
      setallSubStatus(res?.result?.statuses);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const handleGetLeadStatusGroup = async () => {
    startApiCall(seterrorMessage, setLoaderGroup);
    const data = {
      page: 1,
      limit: 300,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
        moduleType: "Lead",
      },
    };
    const res = await ApiCall(
      "post",
      StatusRelatedEndPoints.getGropusDashboard,
      data
    );
    if (res?.success) {
      setallStatusGroup(res?.result?.data);
      setLoaderGroup(false);
    } else {
      setLoaderGroup(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && selectedInitialStatus) {
      handleGetAllEmployeeCount();
      closeAllModals();
    }
  }, [selectedInitialStatus, startDate, endDate]);

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetLeadStatusGroup();
    }
  }, []);

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex) {
      const selectedGroup = allStatusGroup[selectedIndex];
      setleadStatusGroup(selectedGroup);
      setInitialStatuses(selectedGroup?.InitialStatus);
    } else {
      setleadStatusGroup("");
      setInitialStatuses("");
    }
  };

  useEffect(() => {
    if (allStatusGroup?.length > 0) {
      setleadStatusGroup(allStatusGroup?.[0]);
      setInitialStatuses(allStatusGroup?.[0]?.InitialStatus);
      setselectedInitialStatus(allStatusGroup?.[0]?.InitialStatus?.[0]?._id);
      setselectedInitialStatusName(allStatusGroup?.[0]?.InitialStatus);
    }
  }, [allStatusGroup]);

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setselectedInitialStatus(status);
    setselectedInitialStatusName(
      initialStatuses?.filter((sts) => sts?._id === status)
    );
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <EmployeeCrmCountHeader
            allStatusGroup={allStatusGroup}
            leadStatusGroup={leadStatusGroup}
            handleSelectChange={handleSelectChange}
            loaderGroup={loaderGroup}
            initialStatuses={initialStatuses}
            selectedInitialStatus={selectedInitialStatus}
            handleStatusChange={handleStatusChange}
            startDate={startDate}
            setstartDate={setstartDate}
            endDate={endDate}
            setendDate={setendDate}
            today={today}
            loader={loader}
            handleGetAllEmployeeCount={handleGetAllEmployeeCount}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && empAnalytics?.length > 0 && (
                <EmployeeCrmCountsTable
                  empAnalytics={empAnalytics}
                  selectedInitialStatusName={selectedInitialStatusName}
                  allSubStatus={allSubStatus}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {!loader && !empAnalytics && <NoData />}
              {loader && <DataLoader title="data is loading..." />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeCrmCounts;
