import React, { useState } from "react";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { activityGroupRelatedEndpoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  navigateToContactOverview,
  navigateToDealOverview,
  navigateToLeadsOverviewDetails,
} from "../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

const CrmTaskTable = (props) => {
  const {
    allTask,
    page,
    pageLimit,
    setactivityDetails,
    setassigneeEmpModal,
    setassigneeDetails,
    setrelatedTo,
    handleGetMandatoryTask,
  } = props;
  const user = useSelector((state) => state.user);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [taskId, settaskId] = useState("");
  const [allStatus, setallStatus] = useState([]);
  const navigate = useNavigate();
  const currentWorkspaceId = getCurrentWorkspaceId();

  const handleGetAllStatus = async (id) => {
    startApiCall(setErrorMessage, setloader2);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityGroupId: id,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivitySubStatus,
      data
    );
    if (res?.success) {
      setallStatus(res?.status?.data);
      setloader2(false);
    } else {
      setloader2(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8 text-center">
          <thead>
            <tr>
              <th>No .</th>
              <th className="text-start">Title</th>
              <th className="text-start ps-6">Status</th>
              <th>Deadline</th>
              <th>Assignee</th>
              <th className="text-center">Related To</th>
              <th className="text-center">Created At</th>
              <th className="text-start">Created By</th>
            </tr>
          </thead>
          <tbody>
            {allTask?.map((task, index) => {
              return (
                <tr>
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td className="text-start">
                    <div
                      className="text-capitalize text-truncate pointer text-link w-200p"
                      data-bs-toggle="modal"
                      data-bs-target="#call_details_modal"
                      onClick={() => {
                        setactivityDetails(task);
                        setrelatedTo(task?.relatedTo);
                      }}
                    >
                      {task?.title}
                    </div>
                  </td>
                  <td>
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown text-uppercase">
                        <span
                          className="d-flex align-items-center gap-1 fs-7 text-capitalize dropdown-toggle fw-bold"
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          onClick={() =>
                            handleGetAllStatus(task?.activityGroup?._id)
                          }
                          style={{ maxWidth: "200px" }}
                        >
                          <p
                            className="m-0"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill={task?.activityGroupStatus?.color}
                              className="bi bi-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <circle cx="8" cy="8" r="8" />
                            </svg>
                            &nbsp;
                            {task?.activityStatus?.name}
                          </p>
                        </span>

                        <div
                          role="menu"
                          className="dropdown-menu"
                          style={{
                            maxHeight: "30vh",
                            overflow: "auto",
                          }}
                        >
                          {loader2 ? (
                            <span className="text-center">Loading...</span>
                          ) : allStatus?.length > 0 ? (
                            <>
                              {allStatus?.map((status, index) => {
                                return (
                                  <>
                                    <button
                                      className="dropdown-item text-capitalize  d-flex align-items-center border-bottom rounded-0"
                                      type="button"
                                      onClick={() => {
                                        handleGetMandatoryTask(
                                          task?._id,
                                          status?._id,
                                          task,
                                          status?.name
                                        );
                                      }}
                                      disabled={
                                        status?._id ===
                                        task?.activityStatus?._id
                                      }
                                    >
                                      {index + 1}. {status?.name} (
                                      {status?.activityGroupStatus?.name})
                                    </button>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <span> No Status Found</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="w-100 overflow-hide nowrap mb-00 fs-7 text-uppercase text-center">
                      {task?.dealLineDate
                        ? dateAndTimeFormatter(task?.dealLineDate)
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <div className="text-center">
                      {task?.assignees?.length > 0 ? (
                        <>
                          {task?.assignees?.length === 1 ? (
                            <>
                              <Avatar
                                name={task?.assignees?.[0]?.name}
                                count={1}
                                image={task?.assignees?.[0]?.image}
                                size="xxs"
                                color="primary"
                              />
                              &nbsp;
                              {getName(task?.assignees?.[0], user?._id)}&nbsp;
                              {!task?.assignees?.[0]?.permissions?.some(
                                (item) =>
                                  item?.businessId === currentWorkspaceId
                              ) && (
                                <span className="badge badge-sm badge-red">
                                  Removed
                                </span>
                              )}
                            </>
                          ) : (
                            <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer">
                              {task?.assignees?.slice(0, 1)?.map((emp) => {
                                return (
                                  <Avatar
                                    name={emp?.name}
                                    count={1}
                                    image={emp?.image}
                                    size="xxs"
                                    color="primary"
                                  />
                                );
                              })}
                              {task?.assignees?.length > 1 && (
                                <div
                                  className="avatar avatar-xs avatar-soft-indigo avatar-rounded"
                                  onClick={() => {
                                    setassigneeDetails(task);
                                    setassigneeEmpModal(true);
                                  }}
                                >
                                  <span className="initial-wrap">
                                    +{task?.assignees?.length - 1}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        "No Assignee"
                      )}
                    </div>
                  </td>
                  <td>
                    <span className="text-capitalize text-start text-truncate w-180p">
                      {task?.relatedTo} -{" "}
                      {task?.actionOn?.name
                        ? task?.actionOn?.name
                        : task?.actionOn?.title}
                    </span>
                  </td>
                  <td>{dateAndTimeFormatter(task?.createdAt)}</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-between gap-5">
                      <span>
                        {" "}
                        <Avatar
                          name={task?.createdBy?.name}
                          count={1}
                          image={task?.createdBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(task?.createdBy, user?._id)}
                      </span>

                      {task?.actionOn && (
                        <span
                          className="btn btn-icon btn-rounded btn-flush-light btn-sm border"
                          onClick={() => {
                            if (task?.relatedTo === "Lead") {
                              navigate(
                                navigateToLeadsOverviewDetails({
                                  id: task?.actionOn?._id,
                                })
                              );
                            } else if (task?.relatedTo === "Contact") {
                              navigate(
                                navigateToContactOverview({
                                  id: task?.actionOn?._id,
                                })
                              );
                            } else {
                              navigate(
                                navigateToDealOverview({
                                  id: task?.actionOn?._id,
                                })
                              );
                            }
                          }}
                        >
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg>
                          </span>
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CrmTaskTable;
