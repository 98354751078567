import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo/bizhoodlogo.png";
import fullLogo from "../../assets/images/logo/bizhoodfullLogo.png";
import allModules from "../../Navigation/AllModules";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  startApiCall,
  isUserSuperAdmin,
  isCurrentWorkspaceDefault,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { spaceEndpoint } from "../../api/Endpoints";
import { navigateToIndividualSpaces } from "../../Navigation/Navigation";
import AddSpace from "../../pages/Spaces/AddSpace";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../Constant/Route-Constants";
import { ACTIVE_VERSION } from "../../api/base";
import CallReminder from "../../pages/CRM/CrmCalls/CallReminder";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const { handleSidebarActive, user, dashboardCounts, isActive } = props;
  const location = useLocation();
  const currentPathName = location.pathname;
  const [mainRoute, setmainRoute] = useState("");
  const [subRoute, setsubRoute] = useState("");
  const [spaceSubRoute, setspaceSubRoute] = useState("");
  const [spaceData, setSpaceData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const { allModulePermission, allModuleAccess, approvedWorkspace } =
    useSelector((state) => state);
  // const myApproveWorkspaces = useSelector((state) => state.approvedWorkspace);
  const currentWorkspaceId = localStorage.getItem("current-workspace");
  const isModuleReadWriteAccessForSpace =
    allModuleAccess?.[PRIVATEROUTES.SPACE];
  const [hasAccessMainModules, sethasAccessMainModules] = useState([]);

  const checkHasAccessOfMainModule = () => {
    const permissionSet = new Set(allModulePermission || []);
    // Start with default modules
    let temp = ["Spaces", "Workspace"];
    // Filter menuItems based on subItems permissions
    const additionalModules =
      allModules?.[0]?.menuItems
        ?.filter((mItem) =>
          mItem?.subItems?.some((sItem) => permissionSet.has(sItem?.name))
        )
        ?.map((mItem) => mItem.name) || [];

    // Add the additional modules to temp
    temp = temp.concat(
      isUserSuperAdmin(user)
        ? additionalModules
        : additionalModules.filter((moduleName) => moduleName !== "Approval")
    );

    return temp;
  };

  useEffect(() => {
    sethasAccessMainModules(checkHasAccessOfMainModule());
  }, [user]);

  const handleCheckModulePermission = (moduleName) => {
    let isPrivate = false;
    isPrivate =
      user?.business?.length > 0
        ? !allModulePermission?.includes(moduleName)
        : true;
    return user?.isSuperAdmin ? false : isPrivate;
  };

  const handleGetSpace = async () => {
    startApiCall(setErrorMessage, setLoader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
      employeeId: user?._id,
    };
    const res = await ApiCall("post", spaceEndpoint.getSpace, sendData);
    if (res?.success) {
      setSpaceData(res?.space);
      setLoader(false);
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetSpace();
    }
  }, []);

  const SpaceSubMenu = spaceData
    ? spaceData?.map((space) => ({
        name: space?.name,
        key: `${
          navigateToIndividualSpaces({
            spacename: space?.name,
            id: space?._id,
          })?.split("/")?.[3]
        }`,
        route: `${navigateToIndividualSpaces({
          spacename: space?.name,
          id: space?._id,
        })}`,
        isPrivate: space?.isPrivate,
        color: space?.themeColor,
      }))
    : [];

  useEffect(() => {
    let main = currentPathName?.split("/")?.[1];
    let sub = currentPathName?.split("/")?.[2];
    let spacesub = currentPathName?.split("/")?.[3];
    setspaceSubRoute(spacesub?.replaceAll("%20", " "));
    setmainRoute(main);
    setsubRoute(sub);
  }, [currentPathName]);

  return (
    <>
      <div className="hk-menu">
        {/* <!-- Brand --> */}
        <div className="menu-header">
          <span>
            <Link
              className="navbar-brand"
              to="/"
              style={{ display: "flex", gap: "1rem" }}
            >
              <img style={{ width: 40 }} src={logo} alt="" />
              <span className="text-primary mt-2 fw-semibold">BIZHOOD</span>
            </Link>
            <button
              onClick={handleSidebarActive}
              className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle"
            >
              <span className="icon">
                <span className="svg-icon fs-5">
                  {isActive ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-unlock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-lock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1" />
                    </svg>
                  )}
                </span>
              </span>
            </button>
          </span>
        </div>
        {/* <!-- /Brand --> */}

        {/* <!-- Main Menu --> */}
        <div data-simplebar className="nicescroll-bar simple-scrollbar">
          <div className="menu-content-wrap">
            <div className="menu-group">
              <ul className="navbar-nav flex-column">
                <li
                  className={
                    currentPathName === "/" || currentPathName === "/home"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    to="/home"
                    onClick={() => {
                      localStorage.setItem("rawMaterialCategory", "");
                      localStorage.setItem("rawMaterialStockType", "");
                      localStorage.setItem("rawMaterialPage", 1);
                      localStorage.setItem("rawMaterialPageLimit", 50);
                      localStorage.setItem("searchKey", "");
                    }}
                  >
                    <span className="nav-icon-wrap">
                      <span className="svg-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-template"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="16" height="4" rx="1" />
                          <rect x="4" y="12" width="6" height="8" rx="1" />
                          <line x1="14" y1="12" x2="20" y2="12" />
                          <line x1="14" y1="16" x2="20" y2="16" />
                          <line x1="14" y1="20" x2="20" y2="20" />
                        </svg>
                      </span>
                    </span>
                    <span className="nav-link-text">Dashboard</span>
                    <span className="badge badge-sm badge-primary ms-auto">
                      new
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-gap"></div>

            {/* <-----Mapping-----> */}
            {allModules?.map((main, index) => {
              return (
                <div className="menu-group" key={index}>
                  <div className="nav-header">
                    <span>{main.title}</span>
                  </div>
                  <ul className="navbar-nav flex-column">
                    {main?.menuItems?.map((items, index) => {
                      let isShow = hasAccessMainModules?.includes(items?.name);

                      return (
                        isShow && (
                          <>
                            {!isCurrentWorkspaceDefault(
                              approvedWorkspace,
                              currentWorkspaceId
                            ) && items?.name === "My Todo" ? null : (
                              <li
                                key={index}
                                className={
                                  mainRoute === items?.key
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                              >
                                <Link
                                  className="nav-link"
                                  to={items?.route}
                                  data-bs-toggle={
                                    items?.subItems
                                      ? items?.subItems?.length > 0
                                        ? "collapse"
                                        : ""
                                      : items?.name === "Spaces" &&
                                        spaceData?.length > 0
                                      ? "collapse"
                                      : ""
                                  }
                                  data-bs-target={items?.target}
                                >
                                  <span className="nav-icon-wrap">
                                    <span className="position-relative">
                                      <span className="svg-icon">
                                        {items?.svg}
                                      </span>
                                      {items?.isCount &&
                                        dashboardCounts?.[items?.countType] !==
                                          0 && (
                                          <span className="badge badge-success badge-indicator position-top-end-overflow-1"></span>
                                        )}
                                    </span>
                                  </span>
                                  {items?.name === "Spaces" ? (
                                    <span className="nav-link-text">
                                      {items?.name}&nbsp;
                                      <span className="badge badge-pill badge-sm badge-soft-primary">
                                        {spaceData?.length}
                                      </span>
                                      {!isCurrentWorkspaceDefault(
                                        approvedWorkspace,
                                        currentWorkspaceId
                                      ) && (
                                        <>
                                          {getCurrentWorkspaceId() &&
                                            isModuleReadWriteAccessForSpace && (
                                              <span
                                                className="badge badge-sm badge-success rounded-circle border-green ms-2"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_space"
                                                style={{
                                                  height: "24px",
                                                  width: "24px",
                                                  borderRadius: "50%",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-plus-lg"
                                                  strokeWidth="10"
                                                  viewBox="0 0 29 17"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                                  />
                                                </svg>
                                              </span>
                                            )}
                                        </>
                                      )}
                                    </span>
                                  ) : (
                                    <span
                                      className="nav-link-text"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "rawMaterialCategory",
                                          ""
                                        );
                                        localStorage.setItem(
                                          "rawMaterialStockType",
                                          ""
                                        );
                                        localStorage.setItem(
                                          "rawMaterialPage",
                                          1
                                        );
                                        localStorage.setItem(
                                          "rawMaterialPageLimit",
                                          50
                                        );
                                        localStorage.setItem("searchKey", "");
                                      }}
                                    >
                                      {items?.name}
                                    </span>
                                  )}
                                </Link>
                                {items?.name !== "Spaces" ? (
                                  <ul
                                    id={items?.id}
                                    className={
                                      mainRoute === items?.key &&
                                      items?.subItems?.length > 0
                                        ? "nav flex-column  nav-children"
                                        : "nav flex-column collapse  nav-children"
                                    }
                                  >
                                    <li className="nav-item">
                                      <ul className="nav flex-column">
                                        {items?.subItems?.map(
                                          (subitem, index) => {
                                            const isHide =
                                              handleCheckModulePermission(
                                                subitem.name
                                              );
                                            return (
                                              /* active */
                                              !isHide && (
                                                <>
                                                  {items?.name !== "CRM" ? (
                                                    <li
                                                      className={
                                                        subRoute ===
                                                        subitem?.key
                                                          ? "nav-item active"
                                                          : "nav-item"
                                                      }
                                                      style={{
                                                        fontWeight:
                                                          subRoute ===
                                                          subitem?.key
                                                            ? 500
                                                            : "",
                                                      }}
                                                      key={index}
                                                      onClick={() => {
                                                        if (
                                                          subitem?.name !==
                                                          "Raw Materials"
                                                        ) {
                                                          localStorage.setItem(
                                                            "rawMaterialCategory",
                                                            ""
                                                          );
                                                          localStorage.setItem(
                                                            "rawMaterialStockType",
                                                            ""
                                                          );
                                                          localStorage.setItem(
                                                            "rawMaterialPage",
                                                            1
                                                          );
                                                          localStorage.setItem(
                                                            "rawMaterialPageLimit",
                                                            50
                                                          );
                                                          localStorage.setItem(
                                                            "searchKey",
                                                            ""
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <Link
                                                        className="nav-link"
                                                        to={subitem?.route}
                                                        data-bs-toggle={
                                                          subitem?.name ===
                                                            "All Spaces" &&
                                                          spaceData?.length > 0
                                                            ? "collapse"
                                                            : ""
                                                        }
                                                        data-bs-target="#dash_subspaces"
                                                      >
                                                        {items?.isModulePermission &&
                                                          handleCheckModulePermission(
                                                            subitem.name
                                                          ) && (
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="16"
                                                              fill="#f24949"
                                                              className="bi bi-person-lock"
                                                              viewBox="0 0 16 16"
                                                            >
                                                              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h5ZM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
                                                            </svg>
                                                          )}
                                                        <span className="nav-link-text ms-2">
                                                          {subitem?.name}&nbsp;
                                                          {subitem?.isCount &&
                                                            dashboardCounts?.[
                                                              items?.countType
                                                            ] > 0 && (
                                                              <span className="badge badge-pill badge-sm badge-soft-warning">
                                                                {
                                                                  dashboardCounts?.[
                                                                    items
                                                                      ?.countType
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                        </span>
                                                      </Link>
                                                    </li>
                                                  ) : (
                                                    <li
                                                      className={
                                                        subRoute ===
                                                        subitem?.key
                                                          ? "nav-item active"
                                                          : "nav-item"
                                                      }
                                                      style={{
                                                        fontWeight:
                                                          subRoute ===
                                                          subitem?.key
                                                            ? 500
                                                            : "",
                                                      }}
                                                      key={index}
                                                      onClick={() => {
                                                        if (
                                                          subitem?.name !==
                                                          "Raw Materials"
                                                        ) {
                                                          localStorage.setItem(
                                                            "rawMaterialCategory",
                                                            ""
                                                          );
                                                          localStorage.setItem(
                                                            "rawMaterialStockType",
                                                            ""
                                                          );
                                                          localStorage.setItem(
                                                            "rawMaterialPage",
                                                            1
                                                          );
                                                          localStorage.setItem(
                                                            "rawMaterialPageLimit",
                                                            50
                                                          );
                                                          localStorage.setItem(
                                                            "searchKey",
                                                            ""
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {subitem?.group && (
                                                        <span className="ms-5 ">
                                                          {subitem?.group}
                                                        </span>
                                                      )}
                                                      <Link
                                                        className="nav-link"
                                                        to={subitem?.route}
                                                        data-bs-toggle={
                                                          subitem?.name ===
                                                            "All Spaces" &&
                                                          spaceData?.length > 0
                                                            ? "collapse"
                                                            : ""
                                                        }
                                                        data-bs-target="#dash_subspaces"
                                                      >
                                                        {items?.isModulePermission &&
                                                          handleCheckModulePermission(
                                                            subitem.name
                                                          ) && (
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="16"
                                                              fill="#f24949"
                                                              className="bi bi-person-lock"
                                                              viewBox="0 0 16 16"
                                                            >
                                                              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h5ZM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
                                                            </svg>
                                                          )}
                                                        <span className="nav-link-text ms-2">
                                                          - {subitem?.name}
                                                          &nbsp;
                                                          {subitem?.isCount &&
                                                            dashboardCounts?.[
                                                              items?.countType
                                                            ] > 0 && (
                                                              <span className="badge badge-pill badge-sm badge-soft-warning">
                                                                {
                                                                  dashboardCounts?.[
                                                                    items
                                                                      ?.countType
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                        </span>
                                                      </Link>
                                                    </li>
                                                  )}
                                                </>
                                              )
                                            );
                                          }
                                        )}
                                      </ul>
                                    </li>
                                  </ul>
                                ) : (
                                  <ul
                                    id={items?.id}
                                    className={
                                      "nav flex-column collapse nav-children"
                                    }
                                  >
                                    <li className="nav-item">
                                      <ul className="nav flex-column">
                                        {SpaceSubMenu?.map((subitem, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className={
                                                spaceSubRoute === subitem?.key
                                                  ? "nav-item active"
                                                  : "nav-item"
                                              }
                                              style={{
                                                fontWeight:
                                                  spaceSubRoute === subitem?.key
                                                    ? 500
                                                    : "",
                                              }}
                                              onClick={() => {
                                                if (
                                                  subitem?.name !==
                                                  "Raw Materials"
                                                ) {
                                                  localStorage.setItem(
                                                    "rawMaterialCategory",
                                                    ""
                                                  );
                                                  localStorage.setItem(
                                                    "rawMaterialStockType",
                                                    ""
                                                  );
                                                  localStorage.setItem(
                                                    "rawMaterialPage",
                                                    1
                                                  );
                                                  localStorage.setItem(
                                                    "rawMaterialPageLimit",
                                                    50
                                                  );
                                                  localStorage.setItem(
                                                    "searchKey",
                                                    ""
                                                  );
                                                }
                                              }}
                                            >
                                              <span className="nav-link justify-content-between">
                                                <span
                                                  className="nav-link-text "
                                                  style={{
                                                    textTransform: "capitalize",
                                                    cursor: "pointer",
                                                    maxWidth: "195px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                  onClick={() => {
                                                    navigate(subitem?.route);
                                                  }}
                                                >
                                                  {index + 1}.&nbsp;
                                                  {subitem?.color !== "" ? (
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="12"
                                                      height="12"
                                                      fill={subitem?.color}
                                                      className="bi bi-square-fill"
                                                      viewBox="0 0 16 16"
                                                      style={{
                                                        borderRadius: "3px",
                                                      }}
                                                    >
                                                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="12"
                                                      height="12"
                                                      fill="currentColor"
                                                      className="bi bi-square"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                    </svg>
                                                  )}
                                                  &nbsp;
                                                  {subitem?.name}
                                                </span>
                                              </span>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  </ul>
                                )}
                              </li>
                            )}
                          </>
                        )
                      );
                    })}
                  </ul>
                </div>
              );
            })}

            {/* Bottom Box */}
            <div className="callout card card-flush bg-blue-light-5 text-center mt-5 w-220p mx-auto">
              <div className="card-body">
                <img style={{ width: "70%" }} src={fullLogo} alt="" />
                <p className="p-sm card-text mt-1">
                  Version : {ACTIVE_VERSION}
                </p>
                {/* <a
                  href="https://nubra-ui.hencework.com/"
                  target="_blank"
                  className="btn btn-primary btn-block"
                >
                  Contact Us
                </a> */}
              </div>
            </div>
            {/* /Bottom Box */}
          </div>
        </div>
        {/* <!-- /Main Menu --> */}
      </div>
      <AddSpace handleGetSpace={handleGetSpace} />
    </>
  );
};

export default Sidebar;
