import React, { useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadImgFile,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  OrderRelatedEndPoints,
  StockRelatedEndPoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";
import StockConfirmModal from "../ManageStock/StockConfirmModal";

const StockChangeModal = ({
  isChangeStockModal,
  selectedItem,
  handleGetAllVendor,
  vendorLoader,
  allVendors,
  handleGetAllMaterial,
}) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setprice] = useState();
  const [reasonType, setReasonType] = useState("Select Reason");
  const [description, setDescription] = useState("");
  const [confirmStock, setConfirmStock] = useState(false);
  const [billNumber, setbillNumber] = useState("");
  const [image1, setimage1] = useState(null);
  const [image2, setimage2] = useState(null);
  const [image3, setimage3] = useState(null);

  //Select Vendor
  const [selectedVendor, setselectedVendor] = useState("");
  const [vendorData, setvendorData] = useState("");
  const [vendorfFlter, setVendorFilter] = useState("");
  const filteredVendors = filterSearchData(allVendors, vendorfFlter);

  //Select Order
  const [allOrders, setAllOrders] = useState([]);
  const [selectOrder, setSelectOrder] = useState("");
  const [orderData, setOrderData] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderLoader, setOrderLoader] = useState(false);
  const filteredOrder = allOrders?.filter((order) =>
    order?.orderNumber?.toString().padStart(3, "0").includes(orderFilter)
  );
  const [allProducts, setAllProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState("");

  const handleConfirm = (e) => {
    e.preventDefault();
    setConfirmStock(true);
  };

  const handleClose = () => {
    setloader(false);
    setErrorMessage("");
    setvendorData("");
    setselectedVendor("");
    setSelectOrder("");
    setOrderData("");
    setReasonType("Select Reason");
    setSelectProduct("");
    setAllProducts([]);
    setQuantity("");
    setprice("");
    setbillNumber("");
    setDescription("");
    setConfirmStock(false);
    setimage1(null);
    setimage2(null);
    setimage3(null);
    const closeModalDom = document.getElementById("change_stock");
    if (closeModalDom) closeModalDom.click();
  };

  // REMOVE STOCK FUNCTION API CALL
  const handleRemoveStock = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      inventoryId: selectedItem?._id,
      quantity,
      stockType: isChangeStockModal==="in"?"new":"out",
      price: price || 0,
      businessId: getCurrentWorkspaceId(),
      stockOutCase: reasonType,
      orderNumber: orderData?.orderNumber?.toString(),
      productId: selectProduct,
      reason: description,
      createdBy: user?._id,
    };
    const res = await ApiCall("post", StockRelatedEndPoints.removeStock, data);
    if (res?.success) {
      handleGetAllMaterial();
      toast.success("Stock out successfully!");
      handleClose();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // ADD STOCK FUNCTION API CALL
  const handleAddStock = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    const uploadImg1 = await handleUploadImgFile(image1);
    const uploadImg2 = await handleUploadImgFile(image2);
    const uploadImg3 = await handleUploadImgFile(image3);

    const stockImages = [
      uploadImg1,
      uploadImg2,
      uploadImg3,
    ].filter((img) => img !== null);
    if (stockImages.length) {
      try {
        for (const image of stockImages) {
          temp.push(image);
        }
      } catch (error) {
        toast.error("Image uploading failed, try again later.");
        return;
      }
    }
      const data = {
        inventories: [
          {
            itemId: selectedItem?._id,
            quantity,
            price: price || 0,
            unit: selectedItem?.unitId,
            perUnitPrice: price / quantity || 0,
          },
        ],
        isAdded: true,
        stockType: "new",
        businessId: getCurrentWorkspaceId(),
        transactionType: "single",
        createdBy: user?._id,
        vendorId: selectedVendor,
        totalAmount: price,
        billNumber,
        images: temp,
      };
      const res = await ApiCall("post", StockRelatedEndPoints.addStock, data);
      if (res?.success) {
        handleGetAllMaterial();
        toast.success("Stock has been added successfully!");
        handleClose();
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    
  };

  // GET ALL ORDERS FUNCTION API CALL
  const handleGetOrders = async () => {
    startApiCall(null, setOrderLoader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "pending",
        isApproved: "approved",
      },
      page: 1,
      limit: 100,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.getOrders, data);
    if (res?.success) {
      setAllOrders(res?.orders?.data);
      setOrderLoader(false);
    } else {
      setOrderLoader(false);
    }
  };

  return (
    <div
      id="change_stock"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h5 className="ps-2 text-capitalize">
              {isChangeStockModal === "out" ? (
                <span className="border px-2 me-2 bg-danger">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="#FFFFFF"
                    className="bi bi-dash-lg mb-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                    />
                  </svg>
                </span>
              ) : (
                <span className="border px-2 me-2 bg-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#FFFFFF"
                    className="bi bi-plus-lg mb-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </span>
              )}
              Stock {isChangeStockModal} for {selectedItem?.name}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={handleConfirm}>
              <div className="row gx-3 px-3">
                {quantity !== "" && (
                  <div className="d-flex justify-content-center">
                    <div
                      className="alert alert-info text-primary fs-7"
                      role="alert"
                    >
                      Selected Quantity for{" "}
                      {isChangeStockModal === "in" ? "Stock In" : "Stock Out"} :
                      <strong> {quantity}</strong>
                    </div>
                  </div>
                )}
                {isChangeStockModal === "in" && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Vendor {reuiredField}
                        <span
                          className="pointer ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#add_vendor"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="primary"
                            class="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                          </svg>
                        </span>
                      </label>
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p`}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => {
                              handleGetAllVendor();
                              setVendorFilter("");
                            }}
                          >
                            {selectedVendor ? (
                              <div className="d-flex justify-content-between">
                                {vendorData}
                                {selectedVendor !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-1"
                                    onClick={() => {
                                      setselectedVendor("");
                                      setVendorFilter("");
                                      setvendorData("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select Vendor"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                placeholder="Search.."
                                className="form-control"
                                value={vendorfFlter}
                                onChange={(e) =>
                                  setVendorFilter(e.target.value)
                                }
                              />
                            </form>
                            {vendorLoader ? (
                              <span className="d-flex text-center mt-1">
                                Loading...
                              </span>
                            ) : (
                              <div
                                style={{
                                  maxHeight: "30vh",
                                  overflow: "auto",
                                }}
                              >
                                {filteredVendors?.map((vendor, index) => (
                                  <div
                                    className="dropdown-item text-capitalize"
                                    key={vendor._id}
                                    onClick={() => {
                                      setselectedVendor(vendor._id);
                                      setvendorData(vendor?.name);
                                    }}
                                  >
                                    {index + 1}. {vendor?.name}
                                  </div>
                                ))}
                              </div>
                            )}
                            {!vendorLoader && filteredVendors?.length === 0 && (
                              <span className="d-flex text-center mt-1">
                                No Vendors found
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isChangeStockModal === "in" && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Vendor Id/Bill/Challan {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Enter Bill Number"
                        value={billNumber}
                        onChange={(e) => setbillNumber(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {isChangeStockModal === "in" && (
                  <div className="row gx-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">
                          Upload Bill Attachment
                        </label>

                        <div className="d-flex dropify-square gap-3 mt-3">
                          {image1 ? (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="file1"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={image1}
                                onChange={(event) => {
                                  setimage1(event);
                                }}
                              />
                              <label
                                htmlFor="file1"
                                className="position-relative pointer"
                              >
                                <img
                                  htmlFor="file1"
                                  id="local_show_img_1"
                                  src={handlePreviewFileInLocal(image1)}
                                  className="dropify-wrapper text-center"
                                  alt="img1"
                                />
                                <span
                                  className="position-top-end-overflow"
                                  onClick={() => {
                                    setimage1(null);
                                    setimage2(null);
                                    setimage3(null);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </span>
                              </label>
                            </>
                          ) : (
                            <span className="dropify-wrapper text-center">
                              <input
                                type="file"
                                name="image"
                                id="file1"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                onChange={(event) => {
                                  setimage1(event);
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-upload"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                              </svg>
                              <label
                                htmlFor="file1"
                                className="pointer text-center"
                              >
                                Upload Image
                              </label>
                            </span>
                          )}
                          {image2 ? (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="file2"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={image2}
                                onChange={(event) => {
                                  setimage2(event);
                                }}
                              />
                              <label
                                htmlFor="file2"
                                className="position-relative pointer"
                              >
                                <img
                                  htmlFor="file2"
                                  id="local_show_img_2"
                                  src={handlePreviewFileInLocal(image2)}
                                  className="dropify-wrapper text-center"
                                  alt="img2"
                                />
                                <span
                                  className="position-top-end-overflow"
                                  onClick={() => {
                                    setimage2(null);
                                    setimage3(null);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </span>
                              </label>
                            </>
                          ) : (
                            <span
                              className={`dropify-wrapper text-center ${
                                !image1 ? "bg-light text-disabled" : ""
                              }`}
                            >
                              <input
                                type="file"
                                name="image"
                                id="file2"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={!image1}
                                onChange={(event) => {
                                  setimage2(event);
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-upload"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                              </svg>
                              <label
                                htmlFor="file2"
                                className="pointer text-center"
                              >
                                Upload Image
                              </label>
                            </span>
                          )}
                          {image3 ? (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="file3"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={image3}
                                onChange={(event) => {
                                  setimage3(event);
                                }}
                              />
                              <label
                                htmlFor="file3"
                                className="position-relative pointer"
                              >
                                <img
                                  htmlFor="file3"
                                  id="local_show_img_3"
                                  src={handlePreviewFileInLocal(image3)}
                                  className="dropify-wrapper text-center"
                                  alt="img3"
                                />
                                <span
                                  className="position-top-end-overflow"
                                  onClick={() => {
                                    setimage3(null);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </span>
                              </label>
                            </>
                          ) : (
                            <span
                              className={`dropify-wrapper text-center ${
                                !image2 ? "bg-light text-disabled" : ""
                              }`}
                            >
                              <input
                                type="file"
                                name="image"
                                id="file3"
                                accept="image/png,image/jpeg"
                                className="input-file"
                                disabled={!image2}
                                onChange={(event) => {
                                  setimage3(event);
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-upload"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                              </svg>
                              <label
                                htmlFor="file3"
                                className="pointer text-center"
                              >
                                Upload Image
                              </label>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Enter Quantity ({selectedItem?.unit?.name}) {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      placeholder="Enter Quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {isChangeStockModal === "in" ? "Total" : "Approx."} Price{" "}
                      {isChangeStockModal === "in"
                        ? reuiredField
                        : "(Optional)"}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Price"
                      required={isChangeStockModal === "in" ? true : false}
                      value={price}
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                </div>
                {isChangeStockModal === "out" && (
                  <>
                    <div className="col-sm-6">
                      <label className="form-label">
                        Reason type {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            required
                            value={reasonType}
                            onChange={(e) => {
                              setReasonType(e.target.value);
                              if (
                                reasonType === "expired" ||
                                reasonType === "cancelled" ||
                                reasonType === "Select Reason"
                              ) {
                                setOrderData("");
                                setSelectOrder("");
                                setSelectProduct("");
                                setAllProducts([]);
                              }
                            }}
                          >
                            <option value="Select Reason">Select Reason</option>
                            <option value="customized">Customization</option>
                            <option value="expired">Expired / Wastage</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {reasonType === "customized" && (
                      <>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              Select Order {reuiredField}
                            </label>
                            <div className="selectable-dropdown">
                              <div className="dropdown selectable-dropdown">
                                <span
                                  className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p `}
                                  aria-expanded="false"
                                  type="button"
                                  required
                                  data-bs-toggle="dropdown"
                                  onClick={() => {
                                    handleGetOrders();
                                    setOrderFilter("");
                                  }}
                                >
                                  {selectOrder ? (
                                    <div className="d-flex justify-content-between">
                                      {orderData?.orderNumber
                                        ?.toString()
                                        .padStart(3, "0")}{" "}
                                      {`(₹ ${orderData?.subTotal})`}
                                      {selectOrder !== "" && (
                                        <button
                                          type="button"
                                          className="btn-close btn-sm ms-1"
                                          onClick={() => {
                                            setSelectOrder("");
                                            setOrderFilter("");
                                            setOrderData("");
                                            setAllProducts([]);
                                          }}
                                          aria-label="Close"
                                        ></button>
                                      )}
                                    </div>
                                  ) : (
                                    "Select Order"
                                  )}
                                </span>
                                <div role="menu" className="dropdown-menu ">
                                  <form
                                    className="d-sm-block d-none"
                                    role="search"
                                  >
                                    <input
                                      type="text"
                                      placeholder="Search.."
                                      className="form-control"
                                      value={orderFilter}
                                      onChange={(e) =>
                                        setOrderFilter(e.target.value)
                                      }
                                    />
                                  </form>
                                  {orderLoader ? (
                                    <span className="d-flex text-center mt-1">
                                      Loading...
                                    </span>
                                  ) : (
                                    <div
                                      style={{
                                        maxHeight: "30vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      {filteredOrder?.map((order, index) => (
                                        <div
                                          className="dropdown-item text-capitalize"
                                          key={order._id}
                                          onClick={() => {
                                            setSelectOrder(order._id);
                                            setOrderData(order);
                                            setAllProducts(order?.products);
                                          }}
                                        >
                                          {index + 1}.{" "}
                                          {order?.orderNumber
                                            ?.toString()
                                            .padStart(3, "0")}{" "}
                                          {`(₹ ${order?.subTotal})`}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  {!orderLoader &&
                                    filteredOrder?.length === 0 && (
                                      <span className="d-flex text-center mt-1">
                                        No Orders found
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label className="form-label">
                            Dish {reuiredField}
                          </label>
                          <div className="form-group">
                            <div className="todo-toolbar">
                              <select
                                className="form-select form-select-md"
                                required
                                disabled={orderData === ""}
                                value={selectProduct}
                                onChange={(e) => {
                                  setSelectProduct(e.target.value);
                                }}
                              >
                                <option value="">Choose Dish</option>
                                {allProducts?.map((pro) => {
                                  return (
                                    <option value={pro?.productId?._id}>
                                      {pro?.productId?.productName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {isChangeStockModal === "out" && (
                  <div className="col-md-12 mt-2">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">
                          Reason {reuiredField}
                        </label>
                      </div>
                      <textarea
                        className="form-control"
                        required
                        rows="3"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                        maxLength={100}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  disabled={
                    quantity === 0 ||
                    (isChangeStockModal === "in" && !selectedVendor)
                  }
                  type="submit"
                  className="btn btn-primary"
                >
                  {/* {loader ? <ApiLoader /> : "Save"} */}
                  Save
                </button>
              </div>
            </form>
          </div>
          {confirmStock && (
            <StockConfirmModal
              setConfirmStock={setConfirmStock}
              handleAddStock={handleAddStock}
              handleRemoveStock={handleRemoveStock}
              quantity={quantity}
              selectType={isChangeStockModal}
              selectItem={selectedItem?.name}
              loader={loader}
              errorMessage={errorMessage}
              setQuantity={setQuantity}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StockChangeModal;
