import React, { useState } from "react";
import { toast } from "react-toastify";
import ApiCall from "../../../../api/ApiCall";
import { taskEndpoint } from "../../../../api/Endpoints";
import constants from "../../../../components/Constant/Constants";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import {
  convertFileSize,
  handleUploadImgFile,
  startApiCall,
} from "../../../../global-functions/globalFunctions";

const AttachmentSection = ({ taskDetails, user, handleGetTaskDetails }) => {
  const [isUploading, setisUploading] = useState(false);

  const handleAddAttachment = async (event) => {
    let isError = false;
    startApiCall(null, setisUploading);
    let uploaded;
    if (event) {
      try {
        uploaded = await handleUploadImgFile(event);
      } catch (error) {
        isError = true;
        toast.error("Attachment uploading failed , try after sometimes.");
        setisUploading(false);
      }
    }
    if (!isError) {
      let attachments = {
        name: event?.target?.files[0]?.name,
        size: event?.target?.files[0]?.size,
        type: event?.target?.files[0]?.type,
        url: uploaded,
      };

      let allAttachment = [...taskDetails?.attachments, attachments];
      const sendData = {
        taskId: taskDetails?._id,
        userId: user?._id,
        attachments: allAttachment,
      };
      const res = await ApiCall("post", taskEndpoint.editTask, sendData);
      if (res?.success) {
        handleGetTaskDetails();
        setisUploading(false);
        toast.success("Attachment Upload Successfully");
      } else {
        setisUploading(false);
      }
    }
  };

  const handleRemoveAttachment = async (indexNo) => {
    let oldAtt = [...taskDetails?.attachments];
    const sendData = {
      taskId: taskDetails?._id,
      userId: user?._id,
      attachments: oldAtt,
    };
    const res = await ApiCall("post", taskEndpoint.editTask, sendData);
    if (res?.success) {
      handleGetTaskDetails();
      setisUploading(false);
      toast.success("Attachment Removed Successfully");
    } else {
      setisUploading(false);
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center justify-content-between mt-3">
        <p className="text-dark fs-5">
          Attachment
          {taskDetails?.attachments?.length > 0 && (
            <span className="badge badge-sm badge-pill badge-soft-primary ms-2">
              {taskDetails?.attachments?.length}
            </span>
          )}{" "}
          &nbsp;
          <CustomTooltip
            text="Attachment max Limit : 3 and size limit : 500KB/Attachment"
            placement="right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="26"
              fill="currentColor"
              className="bi bi-info-circle pointer"
              viewBox="0 0 16 16"
              style={{ marginTop: "-6px" }}
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </CustomTooltip>
        </p>

        {taskDetails?.attachments?.length < constants.ATTACHMENTLIMIT_TASK && (
          <div className="d-flex align-items-center gap-3">
            {!isUploading ? (
              <>
                <input
                  type="file"
                  name="image"
                  id="profilePhoto"
                  accept="/*"
                  className="input-file"
                  onChange={handleAddAttachment}
                />
                <label
                  htmlFor={isUploading ? "" : "profilePhoto"}
                  className="pointer text-center"
                >
                  <span className="btn btn-sm btn-outline-light">
                    Add Attachment
                  </span>
                </label>
              </>
            ) : (
              <span className="btn btn-sm btn-outline-light">
                please wait..
              </span>
            )}
          </div>
        )}
      </div>
      {isUploading && (
        <span>Please wait until attachment finishes uploading...</span>
      )}{" "}
      {taskDetails?.attachments?.length >= constants.ATTACHMENTLIMIT_TASK && (
        <div className="card card-border note-block bg-danger-light-5 w-150">
          <div className="card-body">
            <p>The attachment size exceeds the allowable limit (3/3) .</p>
          </div>
        </div>
      )}
      {taskDetails?.attachments?.length === 0 && !isUploading && (
        <span> No Attachments.</span>
      )}
      <div className="d-flex justify-content-center flex-wrap gap-3 p-2 mt-1">
        {taskDetails?.attachments?.map((att, index) => {
          return (
            <>
              {/* ********** IMAGE VIEW **********  */}
              {att?.type?.includes("image") && (
                <div className="w-180p text-center shadow-hover p-1 border d-flex flex-column gap-1">
                  <img
                    className="w-100 h-100p"
                    style={{ objectFit: "cover" }}
                    src={att?.url}
                    alt=""
                  />
                  <p className="text-dark fs-7 text-truncate mw-150p">
                    {att?.name}
                  </p>
                  <p className="fs-8">{convertFileSize(att?.size)}</p>
                  <div className="d-flex align-items-center justify-content-between m-1">
                    <a
                      href={att?.url}
                      download
                      target="_self"
                      className="btn btn-sm btn-rounded btn-icon btn-outline-light"
                    >
                      <span title="Download Image" className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </span>
                    </a>
                    <span className="btn btn-sm btn-rounded btn-icon btn-outline-danger">
                      <span
                        onClick={() => {
                          if (!isUploading) {
                            handleRemoveAttachment(index);
                          } else {
                            //NO CLICK
                          }
                        }}
                        title="Delete Image"
                        className="feather-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              )}
              {/* ********** ATTACHMENT VIEW **********  */}
              {!att?.type?.includes("image") && (
                <div className="w-180p text-center shadow-hover p-1 border d-flex flex-column gap-1">
                  <div className="w-100 h-100p d-flex align-items-center justify-content-center p-2 border">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="70"
                      height="70"
                      fill="currentColor"
                      className="bi bi-file-earmark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    </svg>
                  </div>
                  <p className="text-dark fs-7 text-truncate mw-150p">
                    {att?.name}
                  </p>
                  <p className="fs-8">{convertFileSize(att?.size)}</p>
                  <div className="d-flex align-items-center justify-content-between m-1">
                    <a
                      href={att?.url}
                      download
                      className="btn btn-sm btn-rounded btn-icon btn-outline-light"
                    >
                      <span title="Download File" className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </span>
                    </a>
                    <span className="btn btn-sm btn-rounded btn-icon btn-outline-danger">
                      <span
                        onClick={() => {
                          if (!isUploading) {
                            handleRemoveAttachment(index);
                          } else {
                            //NO CLICK
                          }
                        }}
                        title="Delete File"
                        className="feather-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default AttachmentSection;
