import React, { useState } from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../api/Endpoints";
import { useSelector } from "react-redux";
import AllRequestDetails from "./AllRequestDetails";
import { toast } from "react-toastify";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import ApproveOrRejectLeave from "./ApproveOrRejectLeave";

const AllRequest = ({ allLeaveRequest, handleGetAllLeaveRequest }) => {
  const user = useSelector((state) => state.user);
  const { allModuleAccess } = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [leaveRequestDetails, setLeaveRequestDetails] = useState([]);
  const [reason, setReason] = useState("");
  const currentDate = new Date();

  const isModuleReadWriteAccessForLeaveRequest =
    allModuleAccess?.[PRIVATEROUTES.LEAVE_REQUEST];

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    seterrorMessage("");
    setIsOpen(true);
  };

  const handleCloseLeaveRequest = () => {
    setIsOpen(false);
    seterrorMessage("");
    setLoader(false);
  };

  // APPROVE OR REJECT LEAVE REQUEST FUNCTION API CALL
  const handleApproveOrRejectLeaveRequest = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const isAnyDateSmaller = leaveRequestDetails?.dates?.some(
      (date) => new Date(date) < currentDate
    );
    if (isAnyDateSmaller && selectedStatus === "Approve") {
      setLoader(false);
      seterrorMessage(
        "The selected leave dates cannot include past dates. Please create a new leave request."
      );
    } else {
      const sendData = {
        leaveId: leaveRequestDetails?._id,
        actionBy: user?._id,
        isApproved: selectedStatus === "Approve" ? true : false,
        employeeId: leaveRequestDetails?.employeeId?._id,
        businessId: getCurrentWorkspaceId(),
        remark: reason,
      };
      const res = await ApiCall(
        "post",
        leaveRelatedEndPoints.approveOrRejectLeaveRequest,
        sendData
      );
      if (res?.success) {
        toast.success(
          `Leave has been ${
            selectedStatus === "Approve" ? "approved" : "rejected"
          } successfully!`
        );
        handleCloseLeaveRequest();
        handleGetAllLeaveRequest();
        setLoader(false);
      } else {
        seterrorMessage(res.error);
        setLoader(false);
      }
    }
  };

  return (
    <>
      {allLeaveRequest?.data?.data?.map((leave) => {
        return (
          <div className="d-flex align-items-center justify-content-between border rounded-4 shadow p-3">
            <div className="d-flex align-items-center gap-2">
              <span className="pointer">
                <Avatar
                  name={leave?.employeeId?.name}
                  count={1}
                  image={leave?.employeeId?.image}
                  size="md"
                  color="primary"
                />
              </span>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center gap-2">
                  <p className="text-dark fs-5 fw-light">
                    {leave?.employeeId?.name}
                  </p>
                  {leave?.employeeId?.permissions?.length === 0 && (
                    <span className="badge badge-sm badge-red">Employee<br/>Removed</span>
                  )}
                </div>

                <p className="fs-7" style={{ textTransform: "capitalize" }}>
                  {leave?.leaveType?.leaveName}
                </p>
                <span>
                  Requested on : {dateAndTimeFormatter(leave?.createdAt)}
                </span>
                {leave?.status === "rejected" ? (
                  <span
                    className="text-primary pointer"
                    onClick={() => {
                      setLeaveRequestDetails(leave);
                      setIsOpenDetailsModal(!isOpenDetailsModal);
                    }}
                  >
                    View details
                  </span>
                ) : (
                  <span className="d-flex">
                    Leave Dates :
                    <span className="ms-2 text-dark">
                      {leave?.dates?.slice(0, 6)?.map((data, index) => (
                        <span key={index}>
                          {dateFormatter(data)}
                          {index < Math.min(5, leave.dates.length - 1) && ", "}
                        </span>
                      ))}
                    </span>
                    {leave?.dates?.length > 6 && (
                      <button
                        className="btn btn-link ms-2 p-0"
                        onClick={() => {
                          setLeaveRequestDetails(leave);
                          setIsOpenDetailsModal(!isOpenDetailsModal);
                        }}
                      >
                        + {leave?.dates?.length - 6} More
                      </button>
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className="d-block align-items-center gap-4">
              <div className="d-flex">
                <div
                  className="card my-auto px-3 py-1"
                  style={{ background: "#ECECEC", minWidth: "200px" }}
                  onClick={() => {
                    setLeaveRequestDetails(leave);
                    setIsOpenDetailsModal(!isOpenDetailsModal);
                  }}
                >
                  <div className="d-flex align-items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chat-left-dots mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <span className="text-truncate text-dark fs-7 w-150p">
                      {leave?.description}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column ms-3 mt-1">
                  {isModuleReadWriteAccessForLeaveRequest && (
                    <>
                      {leave?.status === "pending" && (
                        <div className="d-flex align-items-center gap-2">
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover text-success"
                            onClick={() => {
                              setLeaveRequestDetails(leave);
                              handleStatusChange("Approve");
                            }}
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="29"
                                height="29"
                                fill="#1B802B"
                                class="bi bi-check-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </span>
                          </span>
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                            onClick={() => {
                              setLeaveRequestDetails(leave);
                              handleStatusChange("Reject");
                            }}
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="29"
                                height="29"
                                fill="#AC2222"
                                class="bi bi-x-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </span>
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  {leave?.status !== "pending" && (
                    <span
                      className={`badge badge-soft-${
                        leave?.status === "approved" ? "green" : "red"
                      }`}
                    >
                      {leave?.status}
                    </span>
                  )}
                </div>
              </div>
              {leave?.status !== "rejected" && (
                <span className="d-flex justify-content-end">
                  <div
                    className="text-decoration-underline pointer text-primary fw-semibold fs-8 d-flex align-items-center gap-1 mt-3"
                    onClick={() => {
                      setLeaveRequestDetails(leave);
                      setIsOpenDetailsModal(!isOpenDetailsModal);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <p>View Details</p>
                  </div>
                </span>
              )}
            </div>
          </div>
        );
      })}
      {isOpenDetailsModal && (
        <AllRequestDetails
          leaveRequestDetails={leaveRequestDetails}
          setIsOpenDetailsModal={setIsOpenDetailsModal}
        />
      )}
      <ApproveOrRejectLeave
        isOpen={isOpen}
        onClose={handleCloseLeaveRequest}
        text={`Are You Sure You Want To ${selectedStatus} This Request ?`}
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleApproveOrRejectLeaveRequest}
        setReason={setReason}
        reason={reason}
        selectedStatus={selectedStatus}
      />
    </>
  );
};

export default AllRequest;
