export const depositMoney = (amount) => {
  return (dispatch) => {
    dispatch({
      type: "deposit",
      payload: amount,
    });
  };
};

export const withDrawMoney = (amount) => {
  return (dispatch) => {
    dispatch({
      type: "withdraw",
      payload: amount,
    });
  };
};

export const addAuthenticateUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: "authenticateUser",
      payload: user,
    });
  };
};

export const myApproveWorkspaces = (business) => {
  return (dispatch) => {
    dispatch({
      type: "approvedWorkspaces",
      payload: business || null,
    });
  };
};

export const myPendingWorkspaces = (business) => {
  return (dispatch) => {
    dispatch({
      type: "pendingWorkspaces",
      payload: business || null,
    });
  };
};

export const myModulePermission = (module) => {
  return (dispatch) => {
    dispatch({
      type: "modulePermission",
      payload: module,
    });
  };
};

export const allModuleAccess = (module) => {
  return (dispatch) => {
    dispatch({
      type: "moduleAccess",
      payload: module,
    });
  };
};
export const refreshNavbar = (isNavbar) => {
  return (dispatch) => {
    dispatch({
      type: "navbarRefresh",
      payload: isNavbar,
    });
  };
};

export const setConfiguration = (configuration) => {
  return (dispatch) => {
    dispatch({
      type: "setConfiguration",
      payload: configuration,
    });
  };
};
