import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadImgFile,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";
import { InventorySettingsRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";

const ProfileDetails = ({ isModuleReadWriteAccessForSettings, activeTab }) => {
  const [image, setImage] = useState(null);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [bussinessName, setBussinessName] = useState("");
  const [bussinessContact, setBussinessContact] = useState("");
  const [bussinessMailID, setBussinessMailID] = useState("");
  const [bussinessGSTNumber, setBussinessGSTNumber] = useState("");
  const [businessWebsite, setbusinessWebsite] = useState("");
  const [businessAddress, setbusinessAddress] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [bussinessProfileDetails, setBussinessProfileDetails] = useState([]);
  // Logo
  const handlePreviewFileInLocalEdit = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (bussinessProfileDetails?.logo) {
      return bussinessProfileDetails?.logo;
    } else {
      return null;
    }
  };

  const handleReset = () => {
    setloader2(false);
    setImage(null);
    setBussinessName("");
    setBussinessContact("");
    setBussinessMailID("");
    setBussinessGSTNumber("");
    setIsDisabled(true);
  };

  const setFileForUpload = (event) => {
    setImage(event);
  };

  // GET INVOICE SETTING FUNCTION
  const handleGetBussinessInventoryDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.getInvoiceSetting,
      data
    );
    if (res?.success) {
      setBussinessProfileDetails(res?.setting);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    if (activeTab === "Profile") {
      handleGetBussinessInventoryDetails();
    }
  }, []);

  // ADD INVOICE SETTING FUNCTION
  const handleAddBussinessInventoryDetails = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      businessId: getCurrentWorkspaceId(),
      charges: [],
      logo: uploadImg,
      businessName: bussinessName,
      businessPhone: bussinessContact,
      businessEmail: bussinessMailID,
      businessGSTNumber: bussinessGSTNumber,
      website: businessWebsite,
      fullAddress: businessAddress,
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.addInvoiceSetting,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetBussinessInventoryDetails();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };
  // EDIT INVOICE SETTING FUNCTION
  const handleEditBussinessInventoryDetails = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    let uploadImg = await handleUploadImgFile(image);

    const sendData = {
      settingId: bussinessProfileDetails._id,
      logo: uploadImg,
      businessName: bussinessName,
      businessPhone: bussinessContact,
      businessEmail: bussinessMailID,
      businessGSTNumber: bussinessGSTNumber,
      website: businessWebsite,
      fullAddress: businessAddress,
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.editInvoiceSetting,
      sendData
    );
    if (res?.success) {
      handleReset();
      toast.success("Category edited successfully!");
      handleGetBussinessInventoryDetails();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (bussinessProfileDetails) {
      setImage(bussinessProfileDetails?.logo);
      setBussinessName(bussinessProfileDetails?.businessName);
      setBussinessContact(bussinessProfileDetails?.businessPhone);
      setBussinessMailID(bussinessProfileDetails?.businessEmail);
      setBussinessGSTNumber(bussinessProfileDetails?.businessGSTNumber);
      setbusinessWebsite(bussinessProfileDetails?.website);
      setbusinessAddress(bussinessProfileDetails?.fullAddress);
    }
  }, [bussinessProfileDetails]);

  return (
    <>
      {loader && <DataLoader title="data is loading..." />}
      {!loader && (
        <div className="d-flex flex-column gap-2">
          <div className="card rounded-7">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-3">
                <span
                  className="fs-4 d-flex flex-column"
                  style={{ color: "#525252" }}
                >
                  Profile Details
                </span>
                {bussinessProfileDetails && (
                  <div>
                    <span
                      className="btn btn-outline-secondary rounded-7 d-flex align-items-center"
                      onClick={() => {
                        setIsDisabled(!isDisabled);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                      </svg>
                      &nbsp;Edit
                    </span>
                  </div>
                )}
              </div>
              <span className="position-relative mt-3">
                <form
                  onSubmit={(e) => {
                    if (bussinessProfileDetails) {
                      handleEditBussinessInventoryDetails(e);
                    } else {
                      handleAddBussinessInventoryDetails(e);
                    }
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="row g-5">
                      <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Your logo</label>
                          <div className="d-flex dropify-square">
                            {image ? (
                              <>
                                <input
                                  type="file"
                                  name="image"
                                  id="file"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  onChange={setFileForUpload}
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                />
                                <label
                                  htmlFor="file"
                                  className="position-relative pointer"
                                >
                                  <img
                                    htmlFor="file"
                                    id="local_show_img"
                                    src={
                                      bussinessProfileDetails === ""
                                        ? handlePreviewFileInLocal(image)
                                        : handlePreviewFileInLocalEdit(image)
                                    }
                                    style={{ padding: 5 }}
                                    className="dropify-wrapper text-center w-145p h-145p"
                                    alt="img"
                                  />
                                  {!isDisabled && (
                                    <span
                                      className="position-top-end-overflow"
                                      onClick={() => {
                                        setImage(null);
                                        if (bussinessProfileDetails?.logo) {
                                          setImage(null)
                                        }
                                        setBussinessProfileDetails(
                                          (prevData) => ({
                                            ...prevData,
                                            logo: "",
                                          })
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-x"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </span>
                                  )}
                                </label>
                              </>
                            ) : (
                              <span className="dropify-wrapper text-center w-145p h-145p d-flex flex-column gap-2 align-items-center justify-content-center">
                                <input
                                  type="file"
                                  name="image"
                                  id="file"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  onChange={setFileForUpload}
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-upload"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>
                                <label
                                  htmlFor="file"
                                  className="pointer text-center"
                                >
                                  Upload Logo
                                </label>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="row g-5">
                          <div className="col-6">
                            <div className="d-flex flex-column gap-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Business Name {reuiredField}
                                </label>

                                <input
                                  className="form-control"
                                  type="text"
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                  value={bussinessName}
                                  onChange={(e) => {
                                    setBussinessName(e.target.value);
                                  }}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-label">
                                  Business Phone {reuiredField}
                                </label>
                                <input
                                  className="form-control"
                                  type="tel"
                                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                  title="Please enter a 10-digit phone number"
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                  value={bussinessContact}
                                  onChange={(e) => {
                                    setBussinessContact(e.target.value);
                                  }}
                                  required
                                />
                              </div>
                              <div className="form-group ">
                                <label className="form-label">Website</label>
                                <input
                                  className="form-control"
                                  type="url"
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                  value={businessWebsite}
                                  // pattern="[0-9A-Za-z\s\-,]+"
                                  onChange={(e) => {
                                    setbusinessWebsite(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-flex flex-column gap-3">
                              <div className="form-group">
                                <label className="form-label">Email ID</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                  value={bussinessMailID}
                                  onChange={(e) => {
                                    setBussinessMailID(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-label">Gst Number</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                  value={bussinessGSTNumber}
                                  onChange={(e) => {
                                    setBussinessGSTNumber(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group ">
                                <label className="form-label">Address</label>
                                <textarea
                                  className="form-control rounded-5 w-100"
                                  rows="3"
                                  disabled={
                                    isDisabled && bussinessProfileDetails
                                  }
                                  placeholder="Type here"
                                  onChange={(e) => {
                                    setbusinessAddress(e.target.value);
                                  }}
                                  value={businessAddress}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isModuleReadWriteAccessForSettings && (
                      <>
                        <div className="d-flex justify-content-end gap-3">
                          {!bussinessProfileDetails && (
                            <button type="submit" className="btn btn-primary">
                              {loader ? <ApiLoader /> : "Save"}
                            </button>
                          )}
                          {bussinessProfileDetails && !isDisabled && (
                            <button type="submit" className="btn btn-primary">
                              {loader ? <ApiLoader /> : "Update"}
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileDetails;

// 1st time edit button should not be visible & no input box should be disabled
