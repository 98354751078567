import React, { useState } from "react";
import ApiCall from "../../api/ApiCall";
import { businessEndpoints } from "../../api/Endpoints";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import {
  handlePreviewFileInLocal,
  handleUploadImgFile,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import constants from "../../components/Constant/Constants";

const AddWorkspace = ({ handleGetAllBusiness }) => {
  const user = useSelector((state) => state.user);
  const [name, setname] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [image, setimage] = useState(null);

  const setFileForUpload = (event) => {
    setimage(event);
  };

  const endApiCall = () => {
    setname("");
    setcity("");
    setcountry("");
    setstate("");
    setimage(null);
    setLoader(false);
    const closeModalDom = document.getElementById("add_new_business");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddBusiness = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    let uploadImg = await handleUploadImgFile(image);

    const payload = {
      name: name,
      country: country,
      city: city,
      state: state,
      createdBy: user?._id,
      description: "",
      image: uploadImg,
    };
    const res = await ApiCall("post", businessEndpoints.addBusiness, payload);
    if (res?.success) {
      toast.success("Create Workspace successfully!");
      endApiCall();
      handleGetAllBusiness();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <div
      id="add_new_business"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        pointerEvents: loader ? "none" : "",
      }}
    >
      <form onSubmit={handleAddBusiness}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" onClick={endApiCall}>
                <span aria-hidden="true">x</span>
              </button>
              <h5 className="mb-4">Add New Workspace</h5>
              <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-2">
                <span>Basic Info</span>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex align-items-center justify-content-center dropify-square">
                {image ? (
                  <>
                    <input
                      type="file"
                      name="image"
                      id="file"
                      accept="image/png,image/jpeg"
                      className="input-file"
                      onChange={setFileForUpload}
                    />
                    <label htmlFor="file" className="position-relative pointer">
                      <img
                        htmlFor="file"
                        id="local_show_img"
                        src={handlePreviewFileInLocal(image)}
                        style={{ padding: 5 }}
                        className="dropify-wrapper text-center"
                      />
                      <span
                        className="position-top-end-overflow"
                        onClick={() => setimage(null)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </span>
                    </label>
                  </>
                ) : (
                  <>
                    <span className="dropify-wrapper text-center">
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        onChange={setFileForUpload}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>
                      <label htmlFor="file" className="pointer text-center">
                        Upload Image
                      </label>
                    </span>
                  </>
                )}
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Workspace Name</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                      required
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                      maxLength={constants.FULL_NAME_LENGTH}
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={country}
                      placeholder="Enter Country"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter City"
                      required
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Enter State"
                      value={state}
                      onChange={(e) => {
                        setstate(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer align-items-center">
              <button
                type="button"
                disabled={loader}
                className="btn btn-secondary"
                onClick={endApiCall}
              >
                Discard
              </button>
              <button
                disabled={loader}
                type="submit"
                className="btn btn-primary"
              >
                {loader ? <ApiLoader /> : "Add"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddWorkspace;
