import React from "react";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  getName,
} from "../../../global-functions/globalFunctions";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../../components/Avatar/Avatar";

const EmployeeTable = ({ allEmployees, page, pageLimit, user }) => {
  const currentWorkspaceId = getCurrentWorkspaceId();

  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead>
          <tr className="text-center">
            <th>No.</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th>Date Created</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Business</th>
          </tr>
        </thead>
        <tbody>
          {allEmployees?.map((data, index) => {
            return (
              <tr className="text-center" key={index}>
                <td> {(page - 1) * pageLimit + (index + 1)}.</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                    style={{ width: "300px" }}
                  >
                    <Avatar
                      name={data?.name}
                      count={1}
                      image={data?.image}
                      size="xss"
                      color="primary"
                    />
                    &nbsp;{getName(data, user?._id)}
                    &nbsp;
                    {!data?.permissions?.some((item) => {
                      return item?.businessId?._id === currentWorkspaceId;
                    }) && (
                      <span className="badge badge-sm badge-red">Removed</span>
                    )}
                  </span>
                </td>
                <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                <td>
                  <div className="fs-7">{data.email ? data.email : "-"}</div>
                </td>
                <td>
                  <div className="fs-7">{data.phone ? data.phone : "-"}</div>
                </td>
                <td>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {data?.permissions?.length
                      ? data?.permissions?.length
                      : "No"}{" "}
                    Business &nbsp;
                    {data?.permissions?.length ? (
                      <>
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-information-line info-icon"
                        ></i>
                        <div
                          className="dropdown-menu"
                          style={{ marginRight: "50%" }}
                        >
                          <div className="dropdown-item mw-300p">
                            <div className="row">
                              <div className="d-flex justify-content-between">
                                <span>Business</span>
                                <span>Role</span>
                              </div>
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="row">
                              {data?.permissions?.map((business, index) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between"
                                    key={index}
                                  >
                                    <span className="text-capitalize">
                                      {index + 1}.
                                    </span>
                                    <span
                                      style={{ width: "200px" }}
                                      className="text-truncate"
                                    >
                                      {business.businessId.name}
                                    </span>
                                    <span className="text-capitalize">
                                      {business?.roleName}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default EmployeeTable;
