import React, { useEffect } from "react";
import { useState } from "react";
import {
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadImgFile,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { ItemRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";
import AddUnit from "./AddUnit";

const AddItem = ({
  handleGetAllItems,
  allUnits,
  handleGetAllUnit,
  showImageInput,
  editItemDetails,
  setEditItemDetails,
}) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [itemType, setItemType] = useState("good");
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [addUnitModal, setAddUnitModal] = useState(false);
  const [image, setimage] = useState(null);
  const [filter, setFilter] = useState("");

  const filteredUnits = allUnits?.filter((unt) =>
    unt?.unitName?.toLowerCase()?.includes(filter.toLowerCase())
  );

  const handlePreviewFileInLocalEdit = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (editItemDetails?.images?.[0]) {
      // Return the URL of the existing image if no new file is selected
      return editItemDetails?.images?.[0];
    } else {
      return null;
    }
  };

  const setFileForUpload = (event) => {
    setimage(event);
  };

  useEffect(() => {
    if (editItemDetails) {
      setItemType(editItemDetails?.itemType);
      setItemName(editItemDetails?.itemName);
      setSalePrice(editItemDetails?.salePrice);
      setUnit(editItemDetails?.unit);
      setCostPrice(editItemDetails?.costPrice);
      setItemCode(editItemDetails?.itemCode);
      setimage(
        editItemDetails?.images?.[0] ? editItemDetails?.images?.[0] : ""
      );
    }
  }, [editItemDetails]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setItemType("good");
    setItemName("");
    setItemCode("");
    setSalePrice("");
    setCostPrice("");
    setUnit("");
    setimage(null);
    setEditItemDetails("");
    const closeModalDom = document.getElementById("add_item");
    if (closeModalDom) closeModalDom.click();
  };

  // ADD ITEM FUNCTION API CALL
  const handleAddItem = async (e) => {
    e.preventDefault();

    startApiCall(setErrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const sendData = {
      itemType,
      itemName,
      unit,
      itemCode,
      businessId: getCurrentWorkspaceId(),
      costPrice,
      salePrice,
      createdBy: user?._id,
      images: [uploadImg],
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.addItem, sendData);
    if (res?.success) {
      handleReset();
      handleGetAllItems();
      toast.success("Add item successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // EDIT ITEM FUNCTION API CALL
  const handleEditItem = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      itemId: editItemDetails?._id,
      itemName,
      costPrice,
      salePrice,
      images: [uploadImg],
      actiondBy: user?._id,
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.editItem, data);
    if (res?.success) {
      toast.success("Edit item successfully!");
      handleReset();
      handleGetAllItems();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_item"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editItemDetails !== "" ? "Edit item" : "Add item"}</h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editItemDetails === "") {
                  handleAddItem(e);
                } else {
                  handleEditItem(e);
                }
              }}
            >
              <div className="mh-500p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 w-100">
                  <div className="col-sm-12">
                    <div className="form-inline">
                      <div className="form-group d-flex justify-content-start">
                        <label className="form-label">Item type :</label>
                        <div className="form-check form-check-inline ms-2">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc2"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              required
                              value="good"
                              onClick={() => setItemType("good")}
                              checked={itemType === "good"}
                              disabled={editItemDetails}
                            />
                            <label className="form-check-label fs-7">
                              Goods
                            </label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc3"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              required
                              value="service"
                              onClick={() => setItemType("service")}
                              checked={itemType === "service"}
                              disabled={editItemDetails}
                            />
                            <label className="form-check-label fs-7">
                              Services
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showImageInput && (
                  <div className="d-flex align-items-center justify-content-center dropify-square mt-2">
                    {image ? (
                      <>
                        <input
                          type="file"
                          name="image"
                          id="file"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          disabled={image}
                          onChange={setFileForUpload}
                        />
                        <label
                          htmlFor="file"
                          className="position-relative pointer"
                        >
                          <img
                            htmlFor="file"
                            id="local_show_img"
                            src={
                              editItemDetails === ""
                                ? handlePreviewFileInLocal(image)
                                : handlePreviewFileInLocalEdit(image)
                            }
                            style={{ padding: 5 }}
                            className="dropify-wrapper text-center"
                            alt="img"
                          />
                          <span
                            className="position-top-end-overflow"
                            onClick={() => {
                              setimage(null);
                              if (editItemDetails !== "") {
                                if (editItemDetails?.images?.[0]) {
                                  setimage(null)
                                }
                                setEditItemDetails((prevData) => ({
                                  ...prevData,
                                  images: [null],
                                }));
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </span>
                        </label>
                      </>
                    ) : (
                      <span className="dropify-wrapper text-center">
                        <input
                          type="file"
                          name="image"
                          id="file"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          onChange={setFileForUpload}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                        </svg>
                        <label htmlFor="file" className="pointer text-center">
                          Upload Image
                        </label>
                      </span>
                    )}
                  </div>
                )}
                <div className="row gx-3 w-100">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Name {reuiredField}</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        onChange={(e) => {
                          setItemName(e.target.value);
                        }}
                        value={itemName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-3 w-100">
                  <div className="col-sm-6">
                    <label className="text-dark fw-250 fs-6">
                      Choose unit {reuiredField}
                      <span
                        className="pointer ms-2"
                        onClick={() => {
                          setAddUnitModal(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="primary"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                        </svg>
                      </span>
                    </label>
                    <div className="form-group">
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p mt-2 ${
                              editItemDetails ? "bg-light text-disabled" : ""
                            }`}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            disabled={editItemDetails}
                            onClick={() => setFilter("")}
                          >
                            {unit ? (
                              <div className="d-flex justify-content-between">
                                {unit}
                                {unit !== "Select unit" && !editItemDetails && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setUnit("");
                                      setFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select unit"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search.."
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </form>
                            <div
                              style={{ maxHeight: "30vh", overflow: "auto" }}
                            >
                              {filteredUnits?.map((unt) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={unt.unitName}
                                  onClick={() => setUnit(unt.unitName)}
                                >
                                  {unt.unitName}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label className="text-dark fw-300 fs-6">Code</label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      required
                      disabled={editItemDetails}
                      onChange={(e) => {
                        setItemCode(e.target.value);
                      }}
                      value={itemCode}
                    />
                  </div>
                </div>
                <label className="title title-md title-wth-divider text-primary mb-2">
                  <span>Purchase</span>
                </label>
                <div className="row gx-3 w-100">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="form-label">
                        Price per {unit ? unit : "unit"} {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="Number"
                        required
                        placeholder="Enter cost price"
                        value={costPrice}
                        onChange={(e) => setCostPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <label className="title title-md title-wth-divider text-primary mb-2">
                  <span>Sales</span>
                </label>
                <div className="row gx-3 w-100">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="form-label">
                        Price per {unit ? unit : "unit"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6"
                          height="6"
                          fill="currentColor"
                          className="bi bi-asterisk mb-2 text-danger ms-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                        </svg>
                      </label>
                      <input
                        className="form-control"
                        type="Number"
                        required
                        placeholder="Enter sale price"
                        value={salePrice}
                        onChange={(e) => setSalePrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!unit}
                  >
                    {loader ? (
                      <ApiLoader />
                    ) : editItemDetails === "" ? (
                      "Add"
                    ) : (
                      "Edit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          {addUnitModal && (
            <AddUnit
              setAddUnitModal={setAddUnitModal}
              handleGetAllUnit={handleGetAllUnit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddItem;
