import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadImgFile,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { complaintEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useLocation } from "react-router-dom";

const RegisterNewComplaint = ({ setAllComplaints, anonymousData }) => {
  const location = useLocation();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const user = useSelector((state) => state.user);
  const [complaintDescription, setcomplaintDescription] = useState("");
  const [complaintTitle, setComplaintTitle] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [loader, setLoader] = useState(false);
  const [image, setimage] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");

  const handleCheckboxChange = (event) => {
    setIsAnonymous(event.target.checked);
  };

  const setFileForUpload = (event) => {
    setimage(event);
  };
  const allCategory = ["Dashboard", "Space", "Task", "Others"];

  useEffect(() => {
    if (location?.state) {
      setSelectCategory(location?.state?.moduleType);
    }
  }, []);

  const handleReset = () => {
    setcomplaintDescription("");
    setComplaintTitle("");
    setSelectCategory("");
    setimage(null);
    seterrorMessage("");
    setIsAnonymous("false");
    setLoader(false);
    const closeModalDom = document.getElementById("register_complaint");
    if (closeModalDom) closeModalDom.click();
  };

  const handleRegisterComplaint = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    let uploadImg = await handleUploadImgFile(image);

    const sendData = {
      businessId: getCurrentWorkspaceId(),
      title: complaintTitle,
      description: complaintDescription,
      category: selectCategory ? selectCategory : "Business",
      isPersonal: false,
      raisedBy: user?._id,
      images: [uploadImg],
      referenceModel: selectCategory ? selectCategory : "",
      complaintFor: null,
      complaintOn: location?.state?.moduleId ? location?.state?.moduleId : null,
      isAnonymous: isAnonymous,
    };
    const res = await ApiCall(
      "post",
      complaintEndPoints.addComplaint,
      sendData
    );

    if (res.success) {
      toast.success("Register Complaint successfully!");
      setAllComplaints();
      handleReset();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <div
      id="register_complaint"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-3">Register Grievance</h5>
            <form onSubmit={handleRegisterComplaint}>
              <div className="row gx-3 ">
                <div className="col-sm-8 d-flex justify-content-start">
                  {location?.state && (
                    <div className="form-group">
                      <label className="form-label fs-6">
                        {location?.state?.moduleName}&nbsp;(
                        {location?.state?.moduleType})
                      </label>
                    </div>
                  )}
                </div>
                {anonymousData?.isAnonymous && (
                  <div className="col-sm-4 d-flex justify-content-end">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="customRadioc1"
                        name="isAnonymous"
                        className="form-check-input"
                        checked={isAnonymous}
                        onChange={handleCheckboxChange}
                      />
                      &nbsp;
                      <label className="form-label fs-7">
                        Stay Anonymous &nbsp;
                      </label>
                    </div>
                  </div>
                )}
              </div>

              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Title </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={complaintTitle}
                      onChange={(event) =>
                        setComplaintTitle(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      required
                      onChange={(e) => {
                        setcomplaintDescription(e.target.value);
                      }}
                      value={complaintDescription}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Upload image</label>
                    <div className="d-flex align-items-center justify-content-center dropify-square">
                      {image ? (
                        <>
                          <input
                            type="file"
                            name="image"
                            id="file"
                            accept="image/png,image/jpeg"
                            className="input-file"
                            onChange={setFileForUpload}
                          />
                          <label htmlFor="file" className="pointer">
                            <img
                              htmlFor="file"
                              id="local_show_img"
                              src={handlePreviewFileInLocal(image)}
                              style={{ padding: 5 }}
                              className="dropify-wrapper text-center"
                              alt="img"
                            />
                          </label>
                        </>
                      ) : (
                        <>
                          <span className="dropify-wrapper text-center">
                            <input
                              type="file"
                              name="image"
                              id="file"
                              accept="image/png,image/jpeg"
                              className="input-file"
                              onChange={setFileForUpload}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-upload"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                            </svg>
                            <label
                              htmlFor="file"
                              className="pointer text-center"
                            >
                              Upload Image
                            </label>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Register"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterNewComplaint;
