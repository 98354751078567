import React, { useEffect, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToAllLeads } from "../../../Navigation/Navigation";

function EmployeeCrmCountsTable({
  empAnalytics,
  selectedInitialStatusName,
  allSubStatus,
  startDate,
  endDate,
}) {
  const navigate = useNavigate();
  const [dynamicStatusArr, setdynamicStatusArr] = useState([]);
  const [initialStatus, setinitialStatus] = useState(null);

  const handleCreateArrForStatuses = () => {
    let arr = [];
    Object.keys(empAnalytics[0])?.forEach((item) => {
      if (item !== "permissions") {
        if (item?.includes("initial")) {
          setinitialStatus(item);
        }
        if (!item.includes("employee") && !item?.includes("initial")) {
          arr.push(item);
        }
      }
    });
    setdynamicStatusArr(arr);
  };

  useEffect(() => {
    if (empAnalytics?.length > 0) {
      handleCreateArrForStatuses();
    }
  }, [empAnalytics]);

  return (
    <div className="row gx-3 row-cols-xxl-3 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1 mt-2 w-120-sm">
      {empAnalytics?.map((item, index) => {
        return (
          <div className="col" key={index}>
            <div className="card file-compact-card card-border">
              <div className="card-body">
                <div className="media fmapp-info-trigger">
                  <div className="media-head me-3 pointer">
                    <div className="avatar avatar-icon avatar-soft-blue avatar-sm">
                      <Avatar
                        name={item?.employeeName}
                        count={1}
                        image={item?.employeeImage}
                        size="xs"
                      />
                    </div>
                  </div>
                  <div className="media-body pointer">
                    <div className="d-flex align-items-center gap-3 justify-content-between">
                      <p className="mb-0 file-name w-200p text-capitalize text-truncate text-dark">
                        {item?.employeeName}
                      </p>
                      {item?.permissions?.length === 0 && (
                        <span className="badge badge-sm badge-red">
                          Removed
                        </span>
                      )}
                    </div>
                    <div className="text-truncate fs-8 mb-2">
                      Total {item[initialStatus]}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 mt-2">
                  {dynamicStatusArr?.map((status) => {
                    return (
                      <>
                        {!status?.includes("initial") && (
                          <div key={index} className="d-flex flex-column gap-2">
                            <div
                              className="d-flex justify-content-between pointer"
                              onClick={() => {
                                const filteredSubStatus = allSubStatus?.find(
                                  (sub) => sub?.name === status
                                );
                                const subStatusId = filteredSubStatus;
                                if (subStatusId) {
                                  navigate(navigateToAllLeads(), {
                                    state: {
                                      _id: item?.employeeId,
                                      name: item?.employeeName,
                                      status:
                                        selectedInitialStatusName?.[0]?.name,
                                      subStatus: subStatusId,
                                      startDate: startDate,
                                      endDate: endDate,
                                    },
                                  });
                                } else {
                                  //nothing
                                }
                              }}
                            >
                              <p className="text-link">
                                {status}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  fill="currentColor"
                                  className="bi bi-arrow-right ms-2 "
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                  />
                                </svg>
                              </p>
                              <p>{item[status]}</p>
                            </div>
                            <div
                              className="progress rounded-4"
                              style={{
                                height: "10px",
                                background: "#e1e1e1",
                              }}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${
                                    item[initialStatus] !== 0
                                      ? (item[status] / item[initialStatus]) *
                                        100
                                      : 0
                                  }%`,
                                  backgroundColor: "#99B3FF",
                                }}
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default EmployeeCrmCountsTable;
