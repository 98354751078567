import React, { useEffect, useState } from "react";
import { getWorkSpaceRole } from "../../../global-functions/globalFunctions";

const LeadHeader = ({
  search,
  setSearch,
  setPage,
  handleGetAllLeads,
  statusFilter,
  setstatusFilter,
  setImportCsv,
  setassigneeModal,
  isModuleReadWriteAccessForLeads,
  setexportCsvModal,
  setshowCheckBox,
  showCheckBox,
  setCheckedLeads,
  setstartDate,
  setendDate,
  user,
  leadOverviewCount,
}) => {
  const allType = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Close won",
      value: "close won",
    },
    {
      name: "Close lost",
      value: "close lost",
    },
    {
      name: "Unassigned",
      value: "unassigned",
    },
  ];

  // To track the reset action
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (isReset) {
      handleGetAllLeads();
      leadOverviewCount();
      setIsReset(false);
    }
  }, [isReset, handleGetAllLeads, leadOverviewCount]);

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <h1 className="header-title-font">Leads</h1>
          <div className="ms-3">
            <button
              className="btn btn-xs fs-9 btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
              data-bs-toggle="modal"
              data-bs-target="#create_new_lead"
            >
              Create New Lead
            </button>
          </div>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="me-2">
          <button
            className={`btn btn-xs fs-9 ${
              showCheckBox ? "btn-primary" : "btn-outline-light"
            } flex-shrink-0 d-lg-inline-block header-button-shadow mt-1`}
            onClick={() => {
              setshowCheckBox(!showCheckBox);
              setCheckedLeads({});
            }}
          >
            {statusFilter === "unassigned" ? "Add Assignee" : "Change Assignee"}
          </button>
        </div>

        <div className="me-2">
          <button
            className="btn btn-primary btn-xs mt-1 fs-9"
            onClick={() => setImportCsv(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              className="bi bi-upload me-2"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
            </svg>
            Import CSV
          </button>
        </div>
        {getWorkSpaceRole(user?.permissions, "roleName")?.includes("owner") && (
          <div className="me-2">
            <button
              className="btn btn-primary btn-xs mt-1 fs-9"
              onClick={() => {
                setexportCsvModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-download me-1"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
              </svg>
              Export CSV
            </button>
          </div>
        )}
        <div className="me-2">
          <select
            className="form-select form-select form-select-sm mt-1 fs-9"
            value={statusFilter}
            onChange={(e) => {
              setstatusFilter(e.target.value);
            }}
          >
            {allType.map((status) => (
              <option key={status.value} value={status.value}>
                {status.name}
              </option>
            ))}
          </select>
        </div>
        {isModuleReadWriteAccessForLeads && (
          <div className="me-2">
            <button
              className="btn btn-xs btn-outline-light ms-1 mt-1 fs-9"
              onClick={() => {
                setassigneeModal(true);
              }}
            >
              Select Assignee
            </button>
          </div>
        )}

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={() => {
              setstartDate("");
              setendDate("");
              setIsReset(true);
            }}
          >
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control fs-9"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default LeadHeader;
