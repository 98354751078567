import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  handleUploadImgFile,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { editProfileEndPoints } from "../../api/Endpoints";
import { actionCreators } from "../../redux-setup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import UserProfile from "./UserProfile/UserProfile";
import Security from "./Security/Security";
import Notifications from "./Notifications/Notifications";

const Profile = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [image, setimage] = useState(null);
  const [isUploading, setisUploading] = useState(false);

  const tabs = [
    {
      name: "Personal details",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-person-circle mb-1"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path
            fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
          />
        </svg>
      ),
    },
    {
      name: "Security",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-shield-lock-fill mb-1"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 0 0 1.048-.625 11.8 11.8 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 0 0-1.044-1.263 63 63 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5"
          />
        </svg>
      ),
    },
    // "Notifications",
    // "Delete account",
  ];
  const [activeTab, setActiveTab] = useState("Personal details");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const setFileForUpload = (event) => {
    setimage(event);
  };

  const handleEditProfile = async () => {
    startApiCall(null, setisUploading);
    let uploadImg = await handleUploadImgFile(image);

    const sendData = {
      employeeId: user?._id,
      image: uploadImg,
    };
    const res = await ApiCall(
      "post",
      editProfileEndPoints.editProfile,
      sendData
    );
    if (res?.success) {
      const updatedUser = {
        ...user,
        image: uploadImg,
      };
      dispatch(actionCreators.addAuthenticateUser(updatedUser));
      setisUploading(false);
      toast.success("Profile Picture Updated Successfully");
    } else {
      setisUploading(false);
    }
  };

  useEffect(() => {
    if (image) {
      handleEditProfile();
    }
  }, [image]);

  return (
    <div className="p-3">
      <div className="row g-4">
        <div className="col-2">
          <div className="d-flex flex-column gap-2 pt-10">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  activeTab === tab?.name
                    ? "custom_active custom_tab"
                    : "custom_tab"
                }
                onClick={() => handleClick(tab?.name)}
              >
                {tab?.svg} {tab?.name}
              </div>
            ))}
          </div>
        </div>
        <div className="col-10">
          {activeTab === "Personal details" && (
            <UserProfile
              user={user}
              isUploading={isUploading}
              setFileForUpload={setFileForUpload}
            />
          )}
          {activeTab === "Security" && <Security />}
          {activeTab === "Notifications" && <Notifications />}
          {activeTab === "Delete account" && (
            <div>Delete account content goes here</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
