import React, { useState } from "react";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { activityGroupRelatedEndpoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import {
  navigateToContactOverview,
  navigateToDealOverview,
  navigateToLeadsOverviewDetails,
} from "../../../Navigation/Navigation";

const CrmMeetingTable = (props) => {
  const {
    allMeeting,
    page,
    pageLimit,
    setactivityDetails,
    setassigneeEmpModal,
    setassigneeDetails,
    setrelatedTo,
    handleGetMandatoryTask,
  } = props;
  const user = useSelector((state) => state.user);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [allStatus, setallStatus] = useState([]);
  const navigate = useNavigate();
  const currentWorkspaceId = getCurrentWorkspaceId();

  const handleGetAllStatus = async (id) => {
    startApiCall(setErrorMessage, setloader2);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityGroupId: id,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivitySubStatus,
      data
    );
    if (res?.success) {
      setallStatus(res?.status?.data);
      setloader2(false);
    } else {
      setloader2(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8 text-center">
          <thead>
            <tr>
              <th>No .</th>
              <th className="text-start ps-1">Title</th>
              <th className="text-start px-1">Status</th>
              <th>Deadline</th>
              <th>Assignee</th>
              <th>Starts Time</th>
              <th>Ends Time</th>
              <th className="text-center">Related To</th>
              <th className="text-center">Created At</th>
              <th className="text-start">Created By</th>
            </tr>
          </thead>
          <tbody>
            {allMeeting?.map((meeting, index) => (
              <tr>
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td className="text-start ps-1">
                  <div
                    className="text-capitalize text-truncate pointer text-link w-150p"
                    data-bs-toggle="modal"
                    data-bs-target="#call_details_modal"
                    // style={{ width: "200px" }}
                    onClick={() => {
                      setactivityDetails(meeting);
                      setrelatedTo(meeting?.relatedTo);
                    }}
                  >
                    {meeting?.title}
                  </div>
                </td>
                <td className="px-1">
                  <div className="selectable-dropdown">
                    <div className="dropdown selectable-dropdown text-uppercase">
                      <span
                        className="d-flex align-items-center gap-1 fs-7 text-capitalize dropdown-toggle fw-bold"
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        onClick={() =>
                          handleGetAllStatus(meeting?.activityGroup?._id)
                        }
                        style={{ maxWidth: "200px" }}
                      >
                        <p
                          className="m-0 fs-7 d-flex align-items-center"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill={meeting?.activityGroupStatus?.color}
                            className="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          &nbsp;
                          {meeting?.activityStatus?.name}
                        </p>
                      </span>

                      <div
                        role="menu"
                        className="dropdown-menu"
                        style={{
                          maxHeight: "30vh",
                          overflow: "auto",
                        }}
                      >
                        {loader2 ? (
                          <span className="text-center">Loading...</span>
                        ) : allStatus?.length > 0 ? (
                          <>
                            {allStatus?.map((status, index) => {
                              return (
                                <>
                                  <button
                                    className="dropdown-item text-capitalize  d-flex align-items-center border-bottom rounded-0"
                                    type="button"
                                    onClick={() => {
                                      handleGetMandatoryTask(
                                        meeting?._id,
                                        status?._id,
                                        meeting,
                                        status?.name
                                      );
                                    }}
                                    disabled={
                                      status?._id ===
                                      meeting?.activityStatus?._id
                                    }
                                  >
                                    {index + 1}. {status?.name} (
                                    {status?.activityGroupStatus?.name})
                                  </button>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <span> No Status Found</span>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="w-100 overflow-hiden nowrap fs-7 text-uppercase text-center">
                    {meeting?.dealLineDate
                      ? dateAndTimeFormatter(meeting?.dealLineDate)
                      : "-"}
                  </p>
                </td>
                <td>
                  <div>
                    {meeting?.assignees?.length > 0 ? (
                      <>
                        {meeting?.assignees?.length === 1 ? (
                          <>
                            <Avatar
                              name={meeting?.assignees?.[0]?.name}
                              count={1}
                              image={meeting?.assignees?.[0]?.image}
                              size="xxs"
                              color="primary"
                            />
                            &nbsp;
                            {getName(meeting?.assignees?.[0], user?._id)}&nbsp;
                            {!meeting?.assignees?.[0]?.permissions?.some(
                              (item) => item?.businessId === currentWorkspaceId
                            ) && (
                              <span className="badge badge-sm badge-red">
                                Removed
                              </span>
                            )}
                          </>
                        ) : (
                          <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer">
                            {meeting?.assignees?.slice(0, 1)?.map((emp) => {
                              return (
                                <Avatar
                                  name={emp?.name}
                                  count={1}
                                  image={emp?.image}
                                  size="xxs"
                                  color="primary"
                                />
                              );
                            })}
                            {meeting?.assignees?.length > 1 && (
                              <div
                                className="avatar avatar-xs avatar-soft-indigo avatar-rounded"
                                onClick={() => {
                                  setassigneeDetails(meeting);
                                  setassigneeEmpModal(true);
                                }}
                              >
                                <span className="initial-wrap">
                                  +{meeting?.assignees?.length - 1}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      "No Assignee"
                    )}
                  </div>
                </td>
                <td className="fs-7">
                  {meeting?.startTime
                    ? dateAndTimeFormatter(meeting?.startTime)
                    : "-"}
                </td>
                <td className="fs-7">
                  {meeting?.endTime
                    ? dateAndTimeFormatter(meeting?.endTime)
                    : "-"}
                </td>
                <td>
                  <span className="text-capitalize text-start">
                    {meeting?.relatedTo} -{" "}
                    {meeting?.actionOn?.name
                      ? meeting?.actionOn?.name
                      : meeting?.actionOn?.title}
                  </span>
                </td>
                <td>{dateAndTimeFormatter(meeting?.createdAt)}</td>
                <td>
                  <div className="d-flex align-items-center justify-content-between gap-5">
                    <span>
                      {" "}
                      <Avatar
                        name={meeting?.createdBy?.name}
                        count={1}
                        image={meeting?.createdBy?.image}
                        size="xxs"
                        color="primary"
                      />
                      &nbsp;
                      {getName(meeting?.createdBy, user?._id)}
                    </span>

                    {meeting?.actionOn && (
                      <span
                        className="btn btn-icon btn-rounded btn-flush-light btn-sm border"
                        onClick={() => {
                          if (meeting?.relatedTo === "Lead") {
                            navigate(
                              navigateToLeadsOverviewDetails({
                                id: meeting?.actionOn?._id,
                              })
                            );
                          } else if (meeting?.relatedTo === "Contact") {
                            navigate(
                              navigateToContactOverview({
                                id: meeting?.actionOn?._id,
                              })
                            );
                          } else {
                            navigate(
                              navigateToDealOverview({
                                id: meeting?.actionOn?._id,
                              })
                            );
                          }
                        }}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </span>
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CrmMeetingTable;
