import React, { useEffect, useState } from "react";
import VendorInfo from "./VendorInfo";
import VendorAddress from "./VendorAddress";
import {
  getCurrentWorkspaceId,
  handleUploadImgFile,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { VendorRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddVendor = ({ handleGetAllVendor, setEditVendor, editVendor }) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Vendor Info
  const [step1, setStep1] = useState(true);
  const [image, setimage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [panNo, setPanNo] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const completeFirstStep = name && phone?.length === 10 ? true : false;

  const setFileForUpload = (event) => {
    setimage(event);
  };

  // Address Details
  const [step2, setStep2] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState({
    country: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const [shippingAddress, setShippingAddress] = useState({
    country: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;
    setBillingAddress({
      ...billingAddress,
      [name]: value,
    });
  };

  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };

  const selectSetup1 = () => {
    setStep1(true);
    setStep2(false);
  };

  const selectSetup2 = () => {
    setStep1(false);
    setStep2(true);
  };

  useEffect(() => {
    if (editVendor) {
      setName(editVendor?.name);
      setPhone(editVendor?.phone);
      setEmail(editVendor?.email);
      setimage(editVendor?.image);
      setBankAccounts(editVendor?.bankAccounts);
      setBillingAddress({
        country: editVendor?.billingAddress?.country,
        city: editVendor?.billingAddress?.city,
        zipCode: editVendor?.billingAddress?.pinCode,
        address: editVendor?.billingAddress?.street,
      });
      setShippingAddress({
        country: editVendor?.shippingAddress?.country,
        city: editVendor?.shippingAddress?.city,
        zipCode: editVendor?.shippingAddress?.pinCode,
        address: editVendor?.shippingAddress?.street,
      });
      setcountryCode(editVendor?.countryCode ? editVendor?.countryCode : "");
      setPanNo(editVendor?.pan);
      const billingSameAsShipping =
        editVendor?.billingAddress &&
        editVendor?.shippingAddress &&
        editVendor.billingAddress.country ===
          editVendor.shippingAddress.country &&
        editVendor.billingAddress.city === editVendor.shippingAddress.city &&
        editVendor.billingAddress.pinCode ===
          editVendor.shippingAddress.pinCode &&
        editVendor.billingAddress.street === editVendor.shippingAddress.street;

      setIsSameAddress(billingSameAsShipping);
    }
  }, [editVendor]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    selectSetup1();
    setName("");
    setEmail("");
    setPhone("");
    setPanNo("");
    setcountryCode("");
    setBillingAddress({
      country: "",
      city: "",
      zipCode: "",
      address: "",
    });
    setShippingAddress({
      country: "",
      city: "",
      zipCode: "",
      address: "",
    });
    setimage(null);
    setIsSameAddress("");
    setEditVendor("");
    const closeModalDom = document.getElementById("add_vendor");
    if (closeModalDom) closeModalDom.click();
  };

  // ADD VENDOR FUNCTION API CALL
  const handleAddVendor = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      businessId: getCurrentWorkspaceId(),
      name,
      email,
      phone,
      pan: panNo,
      image: uploadImg,
      billingPinCode: billingAddress.zipCode,
      billingCountry: billingAddress.country,
      billingCity: billingAddress.city,
      billingStreet: billingAddress.address,
      shippingPinCode: shippingAddress.zipCode,
      shippingCountry: shippingAddress.country,
      shippingCity: shippingAddress.city,
      shippingStreet: shippingAddress.address,
      countryCode: countryCode,
      createdBy: user?._id,
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.addVendor, data);
    if (res?.success) {
      handleReset();
      handleGetAllVendor();
      toast.success("Add vendor successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // EDIT VENDOR FUNCTION API CALL
  const handleEditVendor = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      vendorId: editVendor?._id,
      name,
      phone,
      pan: panNo,
      billingPinCode: billingAddress.zipCode,
      billingCountry: billingAddress.country,
      billingCity: billingAddress.city,
      billingStreet: billingAddress.address,
      shippingPinCode: shippingAddress.zipCode,
      shippingCountry: shippingAddress.country,
      shippingCity: shippingAddress.city,
      shippingStreet: shippingAddress.address,
      countryCode: countryCode,
      bankAccounts,
      image: uploadImg,
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.editVendor, data);
    if (res?.success) {
      handleReset();
      handleGetAllVendor();
      toast.success("Add vendor successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_vendor"
      className="modal fade add-new-task"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ zIndex: 9999 }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "70%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editVendor !== "" ? "Edit Vendor" : "Add Vendor"}</h5>
            <div className="border-top mb-2"></div>
            <div className="d-flex gap-3 p-2 px-2">
              <div className="d-flex flex-column gap-3 border rounded-7 p-3 w-260p h-100">
                <p className="text-dark fw-semibold">2 step set up</p>
                <div className="d-flex align-items-center gap-3">
                  <span
                    className={`border bg-${
                      completeFirstStep ? "success" : "light"
                    } circle d-flex align-items-center justify-content-center text-dark`}
                    style={{ height: "28px", width: "28px" }}
                  >
                    {step1 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                    ) : (
                      1
                    )}
                  </span>
                  <p className="text-dark">Basic information</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span
                    className="border bg-light circle d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "28px", width: "28px" }}
                  >
                    {step2 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                    ) : (
                      2
                    )}
                  </span>
                  <p className="text-dark">Address</p>
                </div>
              </div>

              {/* Vendor Info */}
              {step1 && !step2 && (
                <VendorInfo
                  selectSetup2={selectSetup2}
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  phone={phone}
                  setPhone={setPhone}
                  panNo={panNo}
                  setPanNo={setPanNo}
                  setcountryCode={setcountryCode}
                  countryCode={countryCode}
                  editVendor={editVendor}
                  completeFirstStep={completeFirstStep}
                  image={image}
                  setimage={setimage}
                  setFileForUpload={setFileForUpload}
                />
              )}

              {/* Address */}
              {!step1 && step2 && (
                <VendorAddress
                  selectSetup1={selectSetup1}
                  billingAddress={billingAddress}
                  handleChangeBillingAddress={handleChangeBillingAddress}
                  setBillingAddress={setBillingAddress}
                  shippingAddress={shippingAddress}
                  handleChangeShippingAddress={handleChangeShippingAddress}
                  loader={loader}
                  errorMessage={errorMessage}
                  setShippingAddress={setShippingAddress}
                  isSameAddress={isSameAddress}
                  setIsSameAddress={setIsSameAddress}
                  handleAddVendor={handleAddVendor}
                  handleEditVendor={handleEditVendor}
                  editVendor={editVendor}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
