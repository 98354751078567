import React, { useEffect, useState } from "react";
import SettingsHeader from "./SettingsHeader";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  handleUploadImgFile,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { InventorySettingsRelatedEndPoints } from "../../../api/Endpoints";
import AddCharges from "./AddCharges";
import ProfileDetails from "./ProfileDetails";
import OrderCharges from "./OrderCharges";
import { toast } from "react-toastify";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import MenuQRGenerate from "./MenuQRGenerate";

const InventorySetings = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  // Tab
  const [status, setStatus] = useState("orderCharges");
  const handleTabChange = (status) => {
    setStatus(status);
  };
  const allTabs = [
    // {
    //   name: "Profile",
    //   value: "profile",
    // },
    {
      name: "Order Charges",
      value: "orderCharges",
    },
    {
      name: "Menu QR",
      value: "menuQr",
    },
  ];

  const [image, setImage] = useState(null);
  const [bussinessName, setBussinessName] = useState("");
  const [bussinessContact, setBussinessContact] = useState("");
  const [bussinessMailID, setBussinessMailID] = useState("");
  const [bussinessGSTNumber, setBussinessGSTNumber] = useState("");
  const [businessWebsite, setbusinessWebsite] = useState("");
  const [businessAddress, setbusinessAddress] = useState("");

  const [bussinessInvoiceDetails, setBussinessInvoiceDetails] = useState([]);
  const [bussinessProfileDetails, setBussinessProfileDetails] = useState([]);
  const [orderCharges, setOrderCharges] = useState([]);
  const [editCharges, seteditCharges] = useState("");

  const isModuleReadWriteAccessForSettings =
    allModuleAccess?.[PRIVATEROUTES.INVENTORY_SETTINGS];

  const handleReset = () => {
    setloader(false);
    setImage(null);
    setBussinessName("");
    setBussinessContact("");
    setBussinessMailID("");
    setBussinessGSTNumber("");
  };

  // GET INVOICE SETTING FUNCTION
  const handleGetBussinessInventoryDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.getInvoiceSetting,
      data
    );
    if (res?.success) {
      setBussinessInvoiceDetails(res?.setting);
      setBussinessProfileDetails(res?.setting);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  // ADD INVOICE SETTING FUNCTION
  const handleAddBussinessInventoryDetails = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      businessId: getCurrentWorkspaceId(),
      charges: orderCharges,
      logo: [uploadImg],
      businessName: bussinessName,
      businessPhone: bussinessContact,
      businessEmail: bussinessMailID,
      businessGSTNumber: bussinessGSTNumber,
      website: businessWebsite,
      fullAddress: businessAddress,
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.addInvoiceSetting,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetBussinessInventoryDetails();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };
  // EDIT INVOICE SETTING FUNCTION
  const handleEditBussinessInventoryDetails = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const chargeId = orderCharges.map((charge) => charge._id);
    const sendData = {
      settingId: bussinessInvoiceDetails._id,
      charges: chargeId,
      logo: uploadImg,
      businessName: bussinessName,
      businessPhone: bussinessContact,
      businessEmail: bussinessMailID,
      businessGSTNumber: bussinessGSTNumber,
      website: businessWebsite,
      fullAddress: businessAddress,
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.editInvoiceSetting,
      sendData
    );
    if (res?.success) {
      handleReset();
      toast.success("Category edited successfully!");
      handleGetBussinessInventoryDetails();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  // GET ORDER CHARGES FUNCTION
  const handleGetOrderChargesDetails = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.getCharge,
      data
    );
    if (res?.success) {
      setOrderCharges(res?.charges);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetBussinessInventoryDetails();
    handleGetOrderChargesDetails();
    closeAllModals();
  }, []);

  useEffect(() => {
    if (bussinessInvoiceDetails) {
      setImage(bussinessInvoiceDetails?.logo);
      setBussinessName(bussinessInvoiceDetails?.businessName);
      setBussinessContact(bussinessInvoiceDetails?.businessPhone);
      setBussinessMailID(bussinessInvoiceDetails?.businessEmail);
      setBussinessGSTNumber(bussinessInvoiceDetails?.businessGSTNumber);
      setbusinessWebsite(bussinessInvoiceDetails?.website);
      setbusinessAddress(bussinessInvoiceDetails?.fullAddress);
    }
  }, [bussinessInvoiceDetails]);

  const handleRefresh = () => {
    handleGetBussinessInventoryDetails();
    handleGetOrderChargesDetails();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <SettingsHeader handleRefresh={handleRefresh} />

          <CustomTab
            allTabs={allTabs}
            currentTab={status}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                {status === "orderCharges" && (
                  <>
                    {!loader2 && (
                      <OrderCharges
                        loader={loader}
                        orderCharges={orderCharges}
                        seteditCharges={seteditCharges}
                        handleEditBussinessInventoryDetails={
                          handleEditBussinessInventoryDetails
                        }
                        isModuleReadWriteAccessForSettings={
                          isModuleReadWriteAccessForSettings
                        }
                      />
                    )}
                    {loader2 && <DataLoader title="data is loading..." />}
                  </>
                )}
                {status === "menuQr" && (
                  <>
                    <MenuQRGenerate />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <AddCharges
          handleGetBussinessInventoryDetails={
            handleGetBussinessInventoryDetails
          }
          handleGetOrderChargesDetails={handleGetOrderChargesDetails}
          editCharges={editCharges}
          seteditCharges={seteditCharges}
        />
      </div>
    </div>
  );
};

export default InventorySetings;
